@import "_media.scss";
/*-------------- footer --------------*/
.footer {
  padding-bottom: rem(92);

  @include tablets-down() {
    padding-bottom: rem(119);
  }

  &__row {
    margin: 0 rem(-20);
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    @include tablets-down() {
      margin: 0 rem(-10);
    }
  }

  &__top {
    padding: rem(60) 0;
    border-top: rem(2) solid #FEDAE6;
    border-bottom: rem(2) solid #FEDAE6;
    position: relative;

    @include tablets-down() {
      padding: rem(24) 0 rem(46);
    }

    &-col {
      padding: 0 rem(20);

      @include tablets-down() {
        padding: 0 rem(10);
      }

      &:nth-child(1) {
        width: 20%;

        @include tablets-down() {
          width: 100%;
          margin-bottom: rem(36);
        }
      }
      &:nth-child(2) {
        width: 22%;

        @include tablets-down() {
          width: 50%;
          margin-bottom: rem(32);
        }
      }
      &:nth-child(3) {
        width: 20%;

        @include tablets-down() {
          width: 50%;
          margin-bottom: rem(32);
        }
      }
      &:nth-child(4) {
        width: 38%;

        @include tablets-down() {
          width: 100%;
        }
      }
    }
  }

  &__scroll-top {
    background: none;
    outline: none;
    padding: 0;
    border: rem(2) solid #FEDAE6;
    box-sizing: border-box;
    filter: drop-shadow(rem(17) rem(21) rem(41) rgba(92, 117, 214, 0.26));
    border-radius: rem(100);
    display: flex;
    width: rem(55);
    height: rem(55);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: rem(63);

    @include tablets-down() {
      width: rem(60);
      height: rem(60);
      top: rem(36);
    }

    &:after {
      content: "";
      display: block;
      width: rem(16);
      height: rem(18);
      background: url("../img/icons/arrow-top-blue.svg") no-repeat 50% / contain;
      position: absolute;
    }
  }

  &__middle {
    padding: rem(40) 0 rem(80);
    display: flex;
    flex-wrap: wrap;

    @include tablets-down() {
      padding: rem(24) 0 rem(50);
    }

    &-col {
      padding: 0 rem(20);

      @include tablets-down() {
        padding: 0 rem(10);
      }

      &:nth-child(1) {
        width: 20%;

        @include tablets-down() {
          margin-bottom: rem(29);
          width: rem(120);
        }
      }
      &:nth-child(2) {
        width: 22%;

        @include tablets-down() {
          width: calc(100% - #{rem(120)});
          padding-left: rem(32);
          margin-bottom: rem(29);
        }

        @include phones-down() {
          padding-left: rem(14);
        }
      }
      &:nth-child(3) {
        width: 20%;

        @include tablets-down() {
          width: 100%;
          margin-bottom: rem(24);
        }
      }
      &:nth-child(4) {
        width: 38%;

        @include tablets-down() {
          width: 100%;
        }
      }
    }
  }

  &__fixed-bottom {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
  }

  &__сookie {
    padding: rem(20) 0;
    
    background: #FEDAE6;

    @include tablets-down() {
      padding: rem(15) 0;
    }

    .container {
      display: flex;
      align-items: center;

      @include tablets-down() {
        flex-flow: column;
      }
    }

    p {
      padding-right: rem(50);
      font-weight: 500;
      font-size: rem(13);
      line-height: 160%;
      color: #60355A;

      @include tablets-down() {
        padding-right: 0;
        font-size: rem(10);
        margin-bottom: rem(15);
        text-align: center;
      }


      a {
        color: #DE0052;
        border-bottom: 1px solid #DE0052;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }
    }

    .btn {
      border: none;
      width: rem(191);
      height: rem(50);
      flex-shrink: 0;
      outline: none;
      margin-right: rem(60);

      @include tablets-down() {
        width: rem(150);
        font-size: rem(13);
        margin-right: 0;
      }
    }
  }

  &__fixed-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    background: #DE0052;
    font-weight: bold;
    font-size: rem(16);
    line-height: rem(55);
    text-align: center;
    color: #FFFFFF;

    @include tablets-up() {
      display: none;
    }

    &:hover {
      background: lighten($color: #DE0052, $amount: 15);
    }

  }

  &__disclamer {
    background: #FEF5F6;
    padding: rem(25) 0 rem(20);
    text-align: center;

    img {
      width: 100%;
    }

    span {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      @include fz(12, 16);
      color: #F9AFC9;
      text-transform: uppercase;
      display: none;
    }

    @include phones-lg-down() {
      padding: rem(14) 0;

      img {
        display: none;
      }

      span {
        display: inline;
      }
    }
  }

  &__column-between {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-logo {
    width: rem(180);
    margin-bottom: rem(40);
  }

  &-logo2 {
    width: rem(130);

    @include tablets-down() {
      width: 100%;
      max-width: rem(99);
    }
  }

  &-nav {
    width: 100%;
    max-width: rem(290);

    & > ul {
      li {
        &:not(:last-child) {
          margin-bottom: rem(11);
        }

        a {
          font-weight: 500;
          @include fz(15, 20);
          color: #60355A;
          transition: color $tr-hover;

          u {
            text-decoration: none;
            border-bottom: rem(1) solid #FEDAE6;
          }


          &:hover {
            color: #DE0052;
          }
        }
      }
    }
  }

  &-contacts {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &-cookie {
    font-weight: 500;
    @include fz(15, 17);
    color: #A883A3;

    @include tablets-down() {
      margin-top: rem(42);
    }

    a {
      color: #60355A;
      transition: color $tr-hover;

      &:hover {
        color: #DE0052;
      }
    }
  }

  &-text {
    font-weight: 500;
    @include fz(12, 16);
    color: #A883A3;

    @include tablets-down() {
      width: 100%;
      max-width: rem(320);
    }

    @include phones-lg-down() {
      @include fz(10);
    }

    address {
      font-size: inherit;
      font-style: normal;
      line-height: inherit;

      @include tablets-down() {
        font-weight: 600;
      }
    }

    p {
      line-height: inherit;
    }
  }

  &-links {
    a {
      display: block;
      font-weight: 400;
      @include fz(16, 18);
      color: #A883A3;
      transition: color $tr-hover;

      &:not(:last-child) {
        margin-bottom: rem(4);
      }


      &:hover {
        color: #DE0052;
      }

      @include tablets-down() {
        font-weight: 600;
      }

      @include phones-lg-down() {
        @include fz(12);
      }
    }
  }

}
