.step-hero--waiting-baby {
  padding-bottom: rem(66);
  background: url("../img/images/step-hero-waiting-baby2.png") no-repeat calc(50vw - #{rem(300)}) 0% / rem(1300), #FFF0F5;

  @media (max-width: 1200px) {
    background-image: url("../img/images/step-hero-waiting-baby.png");
    background-position: calc(50vw - #{rem(800)}) 0;
    background-size: rem(1500);
  }

  @include phones-lg-down() {
    padding-bottom: rem(20);
    background: url("../img/images/step-hero-waiting-baby-mob.png") no-repeat 50% rem(80)/ contain, #FFF0F5;
  }
}

.elements-life--waiting-baby {
  padding-bottom: rem(114);

  @include tablets-down() {
    padding-bottom: rem(50);
  }

  .elements-life__cover {

    @include tablets-down() {
      padding-top: rem(360);
    }
  }

  .elements-life__properties-row {
    margin-bottom: rem(113);

    @include tablets-down() {
      margin-bottom: rem(45);
    }
  }
}

.elements-life__elements--waiting-baby {
  top: rem(-15);
  left: rem(518);
  position: absolute;
  width: rem(1101);
  height: rem(988);
  background: url("../img/images/elements-life/spot-2.svg") no-repeat 50% / contain;

  @media (max-width: 1360px) {
    left: auto;
    right: rem(-180);
  }

  @include tablets-down() {
    top: rem(28);
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: rem(794);
    height: rem(570);
  }

  .elements-life__product {
    width: rem(537);
    position: absolute;
    left: 350px / 1101px * 100%;
    top: 104px / 988px * 100%;

    @include tablets-down() {
      width: rem(307);
      top: -67px / 570px * 100%;
      left: 230px / 794px * 100%;
    }
  }

  .elements-life__beanbag {
    position: absolute;
    left: 757px / 1101px * 100%;
    top: (422px + 75px) / 988px * 100%;
    width: rem(155);

    @include tablets-down() {
      width: rem(134);
      left: 498px / 794px * 100%;
      top: (40px + 50px) / 570px * 100%;
    }

    > div {
      @include tablets-down() {
        transform: scale(-1, 1);
      }
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__nipple {
    width: rem(177);
    position: absolute;
    top: (435px + 75px) / 988px * 100%;
    left: 267px / 1101px * 100%;

    @include tablets-down() {
      width: rem(124);
      left: 174px / 794px * 100%;
      top: (142px + 50px) / 570px * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__bib {
    width: rem(277);
    position: absolute;
    top: (28px + 75px) / 988px * 100%;
    left: 269px / 1101px * 100%;

    @include tablets-down() {
      width: rem(149);
      left: 170px / 794px * 100%;
      top: (-10px + 50px) / 570px * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__reward {
    width: rem(94);
    position: absolute;
    top: 620px / 988px * 100%;
    left: 796px / 1101px * 100%;

    @include tablets-down() {
      width: rem(55);
      top: 212px / 570px * 100%;
      left: 500px / 794px * 100%;
    }
  }
}

.mom-baby-block {
  background: url("../img/images/mom-and-baby-branch.png") no-repeat 40% rem(90) / rem(1054), #FFF6F8;
  padding-top: rem(205);
  padding-bottom: rem(95);

  @include tablets-down() {
    background: #FFF6F8;
    padding-top: 0;
    padding-bottom: 40px;
  }

  &__cover {
    @include tablets-down() {
      display: flex;
      flex-flow: column-reverse;
      
    }
  }
  &__tooltip-pgn-cover {
    @include tablets-down() {
      display: flex;
      justify-content: center;
      background:url("../img/images/mom-and-baby-branch-mob.png") no-repeat calc(50% - #{rem(20)}) 50% / rem(355);
      margin: 0 -20px 24px;
    }
  }
}

.mom-baby-replaceable {
  margin-bottom: rem(120);

  @include tablets-down() {
    margin-bottom: 0;
  }

  .swiper-container {
    @include tablets-down() {
      overflow: visible;
    }
  }

  .swiper-slide {
    position: relative;

    @include tablets-down() {
      max-height: rem(348);
      overflow: hidden;
      padding: 15px rem(24);
      background: #FFFFFF;
      box-shadow: 0px 4px 24px #F9E5E9;
      border-radius: rem(10);
      width: rem(260);
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;

    @include tablets-down() {
      flex-flow: column;
    }
  }

  &__col {
    width: 100%;

    &:first-child {
      max-width: rem(376);

      @include tablets-down() {
        max-width: 100%;
      }
    }

    &:last-child {
      max-width: rem(343);

      @include tablets-down() {
        display: none;
        max-width: 100%;
      }
    }
  }

  .main-title {
    margin-bottom: rem(75);

    @include tablets-down() {
      font-size: rem(18);
      line-height: rem(22);
      display: flex;
      align-items: center;
      font-weight: bold;
      margin-bottom: rem(20);
    }

    br {
      + br {
        @include tablets-down() {
          display: none;
        }
      }
    }

    &.vit-b9 {
      @include tablets-down() {
        color: #93C582;
      }

      .main-title__ico-el {
        @include tablets-down() {
          background: #EDF9E9;
        }
      }
    }

    &.vit-i {
      @include tablets-down() {
        color: #FA5995;
      }

      .main-title__ico-el {
        @include tablets-down() {
          background: #FEDAE6;
        }
      }
    }

    &.vit-omega3 {
      @include tablets-down() {
        color: #FF8731;
      }

      .main-title__ico-el {
        @include tablets-down() {
          background: #FFE3CF;
        }
      }
    }

    &.vit-d3 {
      @include tablets-down() {
        color: #FFBF3F;
      }

      .main-title__ico-el {
        @include tablets-down() {
          background: #FFEDC8;
        }
      }
    }

    &.vit-e {
      @include tablets-down() {
        color: #6CB8DB;
      }

      .main-title__ico-el {
        @include tablets-down() {
          background: #DBF4FF;
        }
      }
    }

    &__ico-el {
      display: none;
      font-family: 'Raleway';
      align-items: center;
      justify-content: center;
      padding-top: rem(15);
      padding-left: rem(15);
      margin-right: rem(40);
      width: rem(57);
      height: rem(57);
      background: #E9F8E4;
      border-radius: 50%;
      font-weight: bold;
      font-size: rem(48);
      line-height: rem(56);

      sub {
        font-size: rem(18);
        line-height: rem(21);
      }

      @include tablets-down() {
        display: flex;
      }
    }
  }

  h4 {
    font-weight: 300;
    font-size: rem(48);
    line-height: 100%;
    margin-bottom: rem(40);

    @include tablets-down() {
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: bold;
      font-size: rem(14);
      line-height: 140%;
      margin-bottom: rem(20);
    }
  }

  p {
    font-size: rem(18);
    line-height: 160%;
    font-weight: normal;

    @include tablets-down() {
      max-height: rem(200);
      font-size: rem(14);
      line-height: 140%;
      height: rem(170);
    }
  }

  &__read-more {
    display: none;
    font-weight: bold;
    font-size: rem(15);
    line-height: rem(18);
    color: #DE0052;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, #FFFCFD 59.38%, rgba(255, 255, 255, 0) 100%);
    border-radius: rem(5);
    padding: 50px 30px rem(24);
    cursor: pointer;

    @include tablets-down() {
      display: block;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.mom-baby-tooltip-pgn {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -rem(50);

  @include tablets-down() {
    width: rem(360);
    height: rem(320);
    margin-bottom: 0;
  }

  &__item {
    font-family: 'Raleway';
    cursor: pointer;
    background-color: #fff;
    border-radius: rem(100);
    width: rem(240);
    box-shadow: 0px 4px 24px rgba(248, 178, 208, 0.25);
    outline: none;
    transition: .3s;
    flex-shrink: 0;
    margin-bottom: rem(50);
    margin-right: rem(20);

    @include tablets-down() {
      margin: 0;
      width: rem(60);
      height: rem(60);
      border-radius: 50%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:last-child {
      margin-right: 0;
    }

    &-0 {

      @include tablets-down() {
        background: #93C582;
        top: 146px / 320px * 100%;
        left: 21px / 320px * 100%;
      }

      .mom-baby-tooltip-pgn__name-ico {
        background: #EDF9E9;
        padding-left: rem(20);
      }

      .mom-baby-tooltip-pgn__name {
        color: #93C582;
      }

      .mom-baby-tooltip-pgn__small {
        color: #60355A;
      }

    }

    &-1 {

      @include tablets-down() {
        background: #FA5995;
        top: 224px / 320px * 100%;
        left: 57px / 360px * 100%;
      }

      .mom-baby-tooltip-pgn__name-ico {
        background: #FEDAE6;
        padding-top: 0;
        padding-left: 0;
      }

      .mom-baby-tooltip-pgn__name {
        color: #FA5995;
      }

      .mom-baby-tooltip-pgn__small {
        color: #FA5995;
      }
    }

    &-2 {

      @include tablets-down() {
        background: #FF8731;
        top: 262px / 320px * 100%;
        left: 146px / 360px * 100%;
      }

      .mom-baby-tooltip-pgn__name-ico {
        background: #FFE3CF;
        padding-top: rem(7);
      }

      .mom-baby-tooltip-pgn__name {
        color: #FF8731;
      }

      .mom-baby-tooltip-pgn__small {
        color: #FF8731;
      }

      &.mom-baby-tooltip-pgn-active {
        .mom-baby-tooltip-pgn__small {
          color: #242B45;
        }
      }
    }

    &-3 {

      @include tablets-down() {
        background: #FFBF3F;
        top: 224px / 320px * 100%;
        left: 250px / 360px * 100%;
      }

      .mom-baby-tooltip-pgn__name-ico {
        background: #FFEDC8;
        padding-left: rem(20);
      }

      .mom-baby-tooltip-pgn__name {
        color: #FFBF3F;
      }

      .mom-baby-tooltip-pgn__small {
        color: #FFBF3F;
      }
    }

    &-4 {

      @include tablets-down() {
        background: #6CB8DB;
        top: 146px / 320px * 100%;
        left: 281px / 360px * 100%;
      }

      .mom-baby-tooltip-pgn__name-ico {
        background: #DBF4FF;
        padding-left: rem(20);
        padding-top: rem(20);
      }

      .mom-baby-tooltip-pgn__name {
        color: #6CB8DB;
      }

      .mom-baby-tooltip-pgn__small {
        color: #6CB8DB;
      }

    }
  }

  &__inner {
    display: flex;
    align-items: center;
    padding-left: rem(13);
    padding-right: rem(18);
    padding-top: rem(17);
    padding-bottom: rem(12);

    @include tablets-down() {
      padding: 0;
      flex-flow: column;
    }
    
  }

  &__name-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(68);
    height: rem(68);
    border-radius: 50%;
    padding-top: rem(10);
    padding-left: rem(10);
    background: #EDF9E9;
    flex-shrink: 0;
    transition: .3s;

    @include tablets-down() {
      width: auto;
      height: auto;
      border-radius: 0;
      padding: 0 !important;
      text-align: center;
      margin-bottom: rem(2);
      background: none !important;
    }
  }

  &__name {
    font-weight: bold;
    font-size: rem(48);
    line-height: rem(56);
    color: #93C582;

    @include tablets-down() {
      color: #fff !important;
      font-size: rem(24);
      line-height: rem(28);
    }

    sub {
      font-size: rem(18);
      line-height: rem(21);

      @include tablets-down() {
        font-size: rem(13);
        line-height: rem(15);
      }
    }
  }

  &__small {
    padding-left: rem(30);
    font-weight: bold;
    font-size: rem(16);
    line-height: rem(20);

    @include tablets-down() {
      padding-left: 0;
      font-size: rem(8);
      line-height: rem(9);
      color: #fff !important;
      text-align: center;
    }

    span {
      @include tablets-down() {
        display: none;
      }
    }
  }
}

.mom-baby-tooltip-pgn-active {
  @media (min-width: 768px) {
    background: #FEDAE6;

    .mom-baby-tooltip-pgn__name-ico {
      background-color: #fff;
    }
  
    .mom-baby-tooltip-pgn__small {
      color: #60355A;
    }
  }
}

.useful-articles--happy-pregnancy {
  padding-top: rem(85);
  padding-bottom: rem(62);

  @include tablets-down() {
    padding-top: rem(55);
    padding-bottom: rem(70);
  }

  .section-small-title {
    margin-bottom: rem(95);

    @include tablets-down() {
      margin-bottom: rem(55);
    }
  }

  .article-card:not(:last-child) {
    margin-bottom: rem(20);
  }

  .useful-articles__actions {
    margin-bottom: rem(100);

    @include tablets-down() {
      margin-bottom: rem(80);
    }
  }

  .uses-acco-item__head {
    @include tablets-down() {
      align-items: center;
    }
  }
}

.fancybox-container-slider-popup {
  z-index: 8;

  .fancybox-bg,
  .fancybox-infobar,
  .fancybox-toolbar,
  .fancybox-navigation {
    display: none;
  }

  .fancybox-content {
    padding: 0;
    height: 100%;
    padding-top: rem(60);
    padding-bottom: rem(40);
  }

  .fancybox-slide--html {
    padding: 0;
  }

  .swiper-button-custom {
    position: relative;
    top: auto;
    left: auto;
  }

  .swiper-container {
    overflow: visible;
  }
}

.slider-popup {
  position: relative;
  padding-top: rem(60);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 rem(20);
    height: rem(60);
    // position: fixed;
    // top: rem(60);
    // left: 0;
    // width: 100%;
    // background-color: #fff;
    z-index: 2;
  }
  

  &__slider-nav {
    display: flex;
  }

  .swiper-slide {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px #F9E5E9;
    border-radius: rem(10);
    height: auto;
  }

  &__slider-nav-item  {
    width: rem(30);
    height: rem(40);
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: rem(7);
      height: rem(14);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &__swiper-prev {

    &:after {
      justify-content: flex-start;
      background-image: url("../img/icons/chevron-cyan-left.svg");
    }
  }

  &__swiper-next {
    &:after {
      justify-content: flex-end;
      background-image: url("../img/icons/chevron-cyan-right.svg");
    }
  }

  &__close {
    background: none;
    padding: rem(5) 0;
    font-weight: bold;
    font-size: rem(12);
    line-height: 130%;
    color: #F9AFC9;
    display: flex;
    align-items: center;
    border: none;
    outline: none;

    &:after {
      content: "";
      display: block;
      width: rem(14);
      height: rem(13);
      background: url("../img/icons/close.svg") no-repeat 50% / contain;
      margin-left: rem(12);
    }
  }
}

.mom-baby-card {
  padding: 15px rem(20);
  height: 100%;
  padding-bottom: rem(40);

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: rem(20);
  }

  &__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(57);
    height: rem(57);
    background: #E9F8E4;
    font-family: Raleway;
    font-style: normal;
    font-weight: bold;
    font-size: rem(48);
    line-height: rem(56);
    padding-top: rem(15);
    padding-left: rem(15);
    margin-right: rem(40);
    border-radius: 50%;

    sub {
      font-size: rem(18);
      line-height: rem(21);
    }

    &--vit-b9 {
      background: #EDF9E9;
      color: #93C582;

      + .mom-baby-card__title {
        color: #93C582;
      }

    }

    &--vit-i {
      background: #FEDAE6;
      color: #FA5995;

      + .mom-baby-card__title {
        color: #FA5995;
      }

    }

    &--vit-omega3 {
      background: #FFE3CF;
      color: #FF8731;

      + .mom-baby-card__title {
        color: #FF8731;
      }

    }

    &--vit-d3 {
      background: #FFEDC8;
      color: #FFBF3F;

      + .mom-baby-card__title {
        color: #FFBF3F;
      }

    }

    &--vit-e {
      background: #DBF4FF;
      color: #6CB8DB;

      + .mom-baby-card__title {
        color: #6CB8DB;
      }

    }
  }

  &__title {
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(22);
  }

  &__desc {
    h5 {
      font-style: italic;
      font-weight: bold;
      font-size: rem(14);
      line-height: 140%;
      margin-bottom: rem(30);
    }

    p {
      font-size: rem(14);
      line-height: 140%;
    }
  }

  &__section {
    margin-bottom: rem(30);

    &:last-child {
      margin-bottom: 0;
    }
  }
  
}