.become-mom-calculator {
  &-desc {
    font-weight: 500;
    @include fz(16, 26);
    text-align: center;
    color: #613768;
    max-width: rem(1000);
    margin: 0 auto rem(55);

    @include tablets-down() {
      @include fz(14);
      margin-bottom: rem(32);
    }
  }

  &__info {
    font-weight: 500;
    @include fz(16, 26);
    text-align: center;
    color: #F9AFC9;
    margin-bottom: rem(40);

    @include tablets-down() {
      @include fz(14);
      margin-bottom: rem(32);
    }
  }

  &__result {
    padding: rem(48) rem(100);

    &-title {
      font-weight: normal;
      @include fz(30, 39);
      text-align: center;
      color: #60355A;
      margin: 0 auto rem(45);
      max-width: rem(590);

      @include tablets-down() {
        @include fz(24);
        margin-bottom: rem(24);
      }
    }

    @include tablets-down() {
      padding: rem(32) rem(16);
    }
  }

  &__calendar {
    background: #FFF9FB;
    border-radius: rem(20);

    &.fc .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0;
      padding: rem(30) 0;
      justify-content: center;

      @include tablets-down() {
        padding: rem(16) 0;
      }
    }

    &.fc .fc-toolbar-title {
      min-width: rem(208);
      margin: 0 rem(48);
      text-align: center;
      font-weight: bold;
      @include fz(20, 26);
      color: #242B45;

      .title-year {
        color: #DE0052;
      }

      @include tablets-down() {
        margin: 0 rem(16);
        min-width: 0;
      }
    }

    &.fc .fc-button-primary {
      width: rem(34);
      height: rem(34);
      background: #F9AFC9;
      border: rem(2) solid #F9AFC9;
      border-radius: 50%;
      padding: 0;
      transition: background 0.3s, border 0.3s;

      &:hover {
        background-color: #415DC8;
        border: rem(2) solid #415DC8;
      }
    }

    &.fc .fc-button-primary:focus,
    &.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
    &.fc .fc-button-primary:not(:disabled):active:focus {
      box-shadow: 0 0 0 0.2rem rgba(#F9AFC9, 0.5);
    }

    &.fc .fc-button-primary:not(:disabled).fc-button-active,
    &.fc .fc-button-primary:not(:disabled):active {
      background: #F9AFC9;
      border-color: #F9AFC9;
    }

    &.fc-theme-standard td {
      border: rem(3) solid #FFE2EC;
    }

    &.fc-theme-standard th {
      border: rem(3) solid #FFE2EC;
      background: #FFE2EC;
      color: #F9AFC9;
    }

    &.fc .fc-daygrid-day-number {
      color: #60355A;
      font-weight: bold;
    }

    &.fc .fc-has-event .fc-daygrid-day-number {
      color: #ffffff;
      font-weight: bold;
    }

    &.fc .fc-has-event.fc-day-other .fc-daygrid-day-number {
      color: #60355A;
    }

    &.fc-theme-standard .fc-scrollgrid {
      border: rem(1.5) solid #FFE2EC;
    }

    &.fc .fc-daygrid-day.fc-day-today {
      background-color: inherit;
    }

    &.fc .fc-bg-event {
      opacity: 1;

      &::before {
        position: absolute;
        top: rem(20);
        left: rem(20);
        width: rem(20);
        height: rem(20);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 50%;
      }

      &.fertile-day {
        background-color: #95A6EA;
      }

      &.ovulation-day {
        background-color: #DE0052;

        &::before {
          content: "";
          background-image: url('../img/icons/cell.svg');
        }
      }

      &.menstruation-day {
        background-color: transparent;

        &::before {
          content: "";
          background-image: url('../img/icons/drop.svg');
        }
      }
    }

    &.fc .fc-day-other {
      .fc-bg-event {
        opacity: 0.3;
      }
    }

    .fc-toolbar-chunk {
      &:nth-child(2) {
        & > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(35);

    &-item {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: bold;
      @include fz(17, 22);
      color: #613768;

      & + & {
        margin-left: rem(45);
      }

      @include tablets-down() {
        width: 100%;

        & + & {
          margin-left: 0;
          margin-top: rem(16);
        }
      }

      span {
        width: rem(43);
        height: rem(43);
        min-width: rem(43);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(25);

        &.light {
          background: #FFF9FB;
          font-weight: 600;
          @include fz(18, 23);
        }

        &.pink {
          background: #DE0052;
        }

        &.blue {
          background: #95A6EA;
          color: #FFFFFF;
        }

        img {
          width: rem(20);
          height: rem(20);
        }
      }
    }
  }
}

.calcualator {
  &--become-mom {
    .calcualator__step--1 {
      padding-top: rem(50);
      padding-bottom: rem(50);

      @include tablets-down() {
        padding-top: rem(32);
        padding-bottom: rem(32);
      }

      .btn-fill-blue {
        width: 100%;
        max-width: rem(220);
      }
    }

    .become-mom-layout {
      margin-bottom: rem(40);
    }

    &.calcualator .calendar-form {
      margin-top: rem(32);

      @include phones-lg-down() {
        max-width: none;
        margin-top: 0;
      }
    }

    .counter-el {
      @include phones-lg-down() {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}

.become-mom-layout {
  width: 100%;
  $become-mom-layout: &;

  &__inner {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 rem(-5);
  }

  &__col {
    width: 50%;
    padding: 0 rem(5);
    display: flex;
    align-items: stretch;
    flex-direction: column;

    &-row {
      flex-grow: 1;

      & + & {
        margin-top: rem(10);
      }
    }

    @include tablets-down() {
      width: 100%;
      margin-bottom: rem(10);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__field {
    background: #FFF9FB;
    border-radius: rem(20);
    padding: rem(22) rem(24);
    height: 100%;
    text-align: left;

    &-text {
      max-width: rem(220);

      @include tablets-down() {
        max-width: rem(180)
      }

      @include phones-lg-down() {
        margin-bottom: rem(12);
      }
    }

    @include tablets-down() {
      padding: rem(16);
    }
  }

  &__label {
    font-weight: bold;
    @include fz(17, 22);
    color: #613768;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-left: rem(64);

    &::before {
      content: attr(data-num);
      width: rem(42);
      height: rem(42);
      background: #FFE2EC;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      @include fz(18, 23);
      color: #F9AFC9;

      @include tablets-down() {
        width: rem(32);
        height: rem(32);
      }
    }

    @include tablets-down() {
      @include fz(14);
      padding-left: rem(44);
      align-items: flex-start;
    }

    @include phones-lg-down() {
      flex-direction: column;
    }
  }
}

.counter-el {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: rem(2) solid #FEDAE6;
  border-radius: rem(100);
  padding: rem(10) rem(10) rem(10) rem(28);

  @include tablets-down() {
    padding: rem(4) rem(4) rem(4) rem(16);
  }

  &__display {
    width: rem(58);
    margin-right: rem(5);

    input {
      width: 100%;
      font-weight: bold;
      @include fz(18, 23);
      color: #242B45;
      border: 0;
      outline: none;
      background-color: transparent;

      &::-webkit-input-placeholder {
        color: #242B45;
      }
      &::-moz-placeholder {
        color: #242B45;
      }
      &:-ms-input-placeholder {
        color: #242B45;
      }
      &:-moz-placeholder {
        color: #242B45;
      }

      @include tablets-down() {
        @include fz(16);
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__btn {
    width: rem(34);
    height: rem(34);
    border: rem(2) solid #F9AFC9;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: background 0.3s, border 0.3s;

    & + & {
      margin-left: rem(4);
    }

    &--decr {
      background: #F9AFC9 url(../img/icons/arrow-prev-calendar-white.svg) no-repeat 50%/rem(8) rem(10);
    }

    &--incr {
      background: #F9AFC9 url(../img/icons/arrow-next-calendar-white.svg) no-repeat 50%/rem(8) rem(10);
    }

    &:hover {
      background-color: #415DC8;
      border: rem(2) solid #415DC8;
    }
  }
}