@import "_media.scss";
// .fancybox-bg {
//   background: rgba(0, 0, 0, 0.61);
// }

// .slider-modal-base-class {
//   .fancybox-bg {
//     background: rgba(255, 255, 255, 0.8);

//     @include phones-lg-down() {
//       background: rgba(0, 0, 0, 0.6);
//     }
//   }
// }

.fancybox-is-open {
  .fancybox-bg {
    opacity: 1;
  }
}

.fancybox-slide {
  &.common-modal-class {
    @include phones-lg-down() {
      padding: 0 rem(20);
    }
  }
}

.modal {
  display: none;
  background: none;
  padding: 0;
  overflow: visible;
  position: relative;

  input {
    outline: none;
  }

  .fancybox-button {
    &.fancybox-close-small {
      color: white;
      padding: rem(5);
      border-radius: 50%;

      @include tablets-down() {
        width: rem(32);
        height: rem(32);
      }
    }
  }
}
