.calcualator {
  background: #FFE2EC;
  border-radius: rem(20);
  transition: background .5s;
  
  @include phones-lg-down() {
    margin: 0 rem(-20);
  }

  &.result {
    background: none;
    transition: 0s;

    .calcualator__step {
      border-radius: rem(20) rem(20) rem(0) rem(0);
    }
  }


  &__step-wrap {
    background: #FFE2EC;
  }

  &__step {
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 rem(103);
    background: #FFE2EC;
    border-radius: rem(20);
    transition:  background .5s;

    @include tablets-down() {
      padding: 0 rem(25);
    }

    b {
      font-weight: bold;
      font-size: rem(16);
      line-height: 160%;
      text-align: center;
      color: #60355A;
    }

    &-row {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }

  &__field {
    background: #FFF9FB;
    border-radius: rem(20);
    
    b {
      font-weight: bold;
      font-size: rem(17);
      line-height: 130%;
      text-align: center;
      color: #613768;
    }
  }

  a.calcualator__field {
    border: rem(3) solid transparent;

    &:hover {
      border: rem(3) solid #F9AFC9;
    }
  }

  &__step-action-btn {
    font-family: "Montserrat";
    height: rem(70);
    padding: 0 rem(62);
    font-size: rem(16);

    @include phones-lg-down() {
      padding: 0 rem(50);
    }

    &--back {
      @include phones-lg-down() {
        font-size: 0;
        padding: 0 rem(33);
        background: url("../img/icons/arrow-left-purple-light.svg") no-repeat 50% / rem(25);
        
        &:hover {
          background: url("../img/icons/arrow-left-purple.svg") no-repeat 50% / rem(25);
        }
      }
    }
  }

  .calendar-form {
    max-width: rem(334);
    width: 100%;
    margin: 0 auto;
  }

  &__step-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
  }

  &__result {
    border: rem(3) solid #FFE2EC;
    border-radius: rem(0) rem(0) rem(20) rem(20);
  }

  &__result-desc {
    padding: 35px 65px;
    margin-top: 14px;
    line-height: 26px;
    font-size: 16px;
    font-weight: 500;

    @include tablets-down() {
      padding: 35px 25px;
    }

    b {
      font-size: inherit;
    }
  }

  &__baby-size {
    width: rem(457);
    height: rem(457);
    border-radius: 50%;
    background: #FFF9FB;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-weight: 500;
    font-size: rem(17);
    line-height: 130%;
    text-align: center;
    color: #F8B2D0;

    @include tablets-down() {
      width: 100%;
      height: auto;
      background: none;
    }

    &.grapefruit {
      padding-top: rem(60);

      @include tablets-down() {
        padding-top: 0;
      }

      img {
        max-width: rem(197);
        width: 100%;
        margin-bottom: rem(40);
      }
    }

  }

  &--pregnancy {
    .calcualator__step {
      padding-top: rem(55);
      padding-bottom: rem(47);
      min-height: rem(425);

      &--1 {
        padding-bottom: rem(64);

        .calcualator__step-row {
          margin-bottom: rem(45);

          @include tablets-down() {
            flex-flow: column;
            width: 100%;
          }
        }
  
        .calcualator__field {
          max-width: rem(414);
          width: 50%;
          margin: 0 rem(45);
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          height: rem(235);
          padding: rem(46) rem(40);
          cursor: pointer;

          @include tablets-down() {
            padding: rem(25);
            margin: 0 0 rem(25);
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &:nth-child(1) {
            padding-top: rem(52);

            @include tablets-down() {
              padding-top: rem(25);
            }

            img {
              width: rem(75);
              margin-bottom: rem(28);
            }
          }

          &:nth-child(2) {
            padding-top: rem(35);

            @include tablets-down() {
              padding-top: rem(15);
            }

            img {
              width: rem(124);
              margin-bottom: rem(11);
            }
          }

          input[type="radio"] {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      &--2 {
        display: flex;
        flex-flow: column;
        align-items: center;

        .calcualator__field {
          padding: rem(42);
          padding-bottom: rem(48);
          max-width: rem(486);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
          margin-bottom: rem(60);

          @include phones-lg-down() {
            padding: rem(40) rem(15);
          }
        }

        .calendar-form {
          margin-top: rem(28);
        }
      }
    }

    .calcualator {
      &__result {
        padding-top: rem(56);
        padding-left: rem(103);
        padding-right: rem(125);
        padding-bottom: rem(68);
        display: flex;
        align-items: center;
        font-size: rem(30);
        line-height: 140%;
        color: #60355A;

        @include tablets-down() {
          flex-flow: column;
          padding: rem(45) rem(25);
          font-size: rem(18);
        }

        b {
          font-weight: bold;
          color: #DE0052;
        }

        hr {
          display: block;
          height: rem(3);
          border: none;
          background-color: #FEDAE6;
          margin: rem(60) 0;
          padding: 0;
          width: 100%;

          @include tablets-down() {
            margin: rem(25) 0;
          }
        }

        &-content {
          padding-right: rem(88);
          flex-grow: 1;

          @include tablets-down() {
            padding-right: 0;
            width: 100%;
            margin-bottom: rem(25);
          }
        }
      }
    } 
  
  }

  &--hw {
    .calcualator__step {
      padding-top: rem(55);
      padding-bottom: rem(47);
      min-height: rem(425);

      &--1 {
        padding-bottom: rem(64);

        .calcualator__step-row {
          margin-bottom: rem(45);

          @include tablets-down() {
            flex-flow: column;
            width: 100%;
          }
        }
  
        .calcualator__field {
          max-width: rem(414);
          width: 50%;
          margin: 0 rem(45);
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          height: rem(235);
          padding: rem(46) rem(40);
          cursor: pointer;

          @include tablets-down() {
            padding: rem(25);
            margin: 0 0 rem(25);
            width: 100%;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &:nth-child(1) {
            padding-top: rem(49);

            @include tablets-down() {
              padding-top: rem(25);
            }

            img {
              width: rem(95);
              margin-bottom: rem(23);
            }
          }

          &:nth-child(2) {
            padding-top: rem(62);

            @include tablets-down() {
              padding-top: rem(15);
            }

            img {
              width: rem(137);
              margin-bottom: rem(25);
            }
          }

          input[type="radio"] {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      &--2 {
        .calcualator__step-row {
          margin: 0 rem(-13) rem(19);
          width: auto;
          flex-wrap: wrap;
        }

        .calcualator__step-col {
          padding: 0 rem(13);
          margin-bottom: rem(26);

          &:nth-child(1) {
            width: 24.85%;

            @include tablets-down() {
              width: 50%;
            }

            @include phones-lg-down() {
              width: 100%;
            }
          } 

          &:nth-child(2) {
            width: 24.85%;

            @include tablets-down() {
              width: 50%;
            }

            @include phones-lg-down() {
              width: 100%;
            }
          } 

          &:nth-child(3) {
            width: 50.3%;

            @include tablets-down() {
              width: 100%;
            }
          } 
        }

        .calcualator__field {
          padding: rem(15) rem(33);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: column;
          min-height: rem(194);


          @include phones-lg-down() {
            padding: rem(15);
          }
        }

        .calcualator-input {
          margin-top: rem(28);
        }

        .calendar-form {
          margin-top: rem(28);
        }
      }
    }

    .calcualator {
      &__result {
        padding-top: rem(58);
        padding-left: rem(104);
        padding-right: rem(104);
        padding-bottom: rem(99);

        @include tablets-down() {
          flex-flow: column;
          padding: rem(45) rem(25);
          font-size: rem(18);
        }

        &-hw-child {
          margin-bottom: rem(45);
          font-size: rem(30);
          line-height: 130%;
          text-align: center;
          color: #60355A;

          @include phones-lg-down() {
            font-size: rem(18);
          }
  
          img {
            margin: 0 auto rem(35);
          }
        }

        &-hw-status {
          background: #FFF9FB;
          border-radius: rem(20);
          min-height: rem(278);
          padding: rem(78) rem(25) rem(50);

          font-size: rem(17);
          line-height: 130%;
          text-align: center;
          color: #60355A;

          img {
            margin: 0 auto rem(60);
            width: rem(78);
          }

          b {
            font-weight: bold;
          }

          small {
            font-size: rem(15);
            color: #F9AFC9;
          }
        }

        &--girl {
          padding-top: rem(58);
    
          .calcualator__result-hw-child {
            img {
              margin: 0 auto rem(35);
            }
          }
        }

        .row {
          display: flex;
          flex-wrap: wrap;
          margin: 0 rem(-7);
        }

        .col {
          padding: 0 rem(7);
          width: 33.333%;

          @include phones-lg-down() {
            width: 100%;
            margin-bottom: rem(20);

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

      }
    } 
  }
}

.calendar-form {
  background: #FFFFFF;
  border: rem(2) solid #FEDAE6;
  box-sizing: border-box;
  border-radius: rem(100);
  padding: rem(3);
  height: rem(54);
  display: flex;
  justify-content: space-between;
  position: relative;

  input {
    font-family: inherit;
    width: rem(147);
    padding-left: rem(60);
    height: 100%;
    background: url("../img/icons/calendar-small-purple.svg") no-repeat rem(21) 50% / rem(16);
    border: none;
    outline: none;
    font-weight: bold;
    font-size: rem(18);
    color: #242B45;
    flex-grow: 1;

    &::placeholder { 
      opacity: 1;
      color: #E5CAE2;
    }
  }

  button {
    font-size: rem(14);
    height: 100%;
    padding: 0 rem(18);
    width: rem(119);

    @include phones-lg-down() {
      font-size: 0;
      width: rem(50);
      background-image: url("../img/icons/calendar-small-white.svg");
      background-size: rem(18);
      background-position: 50%;
      background-repeat: no-repeat;
    }


    &.active {
      background-color: #DE0052;

      &:hover {
        background-color: #F9AFC9;
      }
    }
  }

  .datepicker-here {
    position: absolute;
    width: 100%;
    top: rem(64);
    left: 0;
    display: none;

    .datepicker {
      width: 100%;
      background: #FFFFFF;
      border: rem(2) solid #FEDAE6;
      box-sizing: border-box;
      border-radius: rem(20);
      font-family: "Montserrat";

      .datepicker--nav {
        padding: rem(16);
        border-bottom: none;
        
        > div {
          &:nth-child(1) {
            order: 2;
            margin-right: rem(4);
          }

          &:nth-child(2) {
            order: 1;
          }

          &:nth-child(3) {
            order: 3;
          }
        }
      }

      .datepicker--nav-title {
        font-weight: bold;
        font-size: rem(18);
        line-height: 130%;
        color: #242B45;
        padding: 0;
        margin-right: auto;
      }

      .datepicker--nav-title i {
        color: #DE0052;
      }

      .datepicker--nav-action {
        background: #F9AFC9;
        border-radius: 50%;
        width: rem(35);
        height: rem(35);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          width: rem(8);
          height: rem(10);
        }

        &[data-action="prev"] {
          &:after {
            background: url("../img/icons/arrow-prev-calendar-white.svg") no-repeat 50% / contain;
          }
        }

        &[data-action="next"] {
          &:after {
            background: url("../img/icons/arrow-next-calendar-white.svg") no-repeat 50% / contain;
          }
        }
      }

      .datepicker--nav-action path {
        stroke: #fff;
      }

      .datepicker--nav-action svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        
      }
      

      .datepicker--content {
        padding: 0 rem(16) rem(22);
      }

      .datepicker--days-names {
        margin-top: 0;
        margin-bottom: 0;
      }

      .datepicker--day-name {
        background: #FFF0F5;
        color: #F9AFC9;
        padding: rem(5);
        border-right: rem(1) solid #fff;
        border-bottom: rem(1) solid #fff;
        text-align: right;
        border-radius: 0;
        font-size: rem(14);
        justify-content: flex-end;
        align-items: flex-end;
        text-transform: lowercase;
      }

      .datepicker--cell-day {
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(17);
        display: flex;
        align-items: center;
        text-align: right;
        background: #FFF9FB;
        color: #60355A;
        border-right: rem(1) solid #fff;
        border-bottom: rem(1) solid #fff;
        height: rem(28);
        border-radius: 0;
        justify-content: flex-end;
        padding-right: rem(5);
      }

      .datepicker--cell.-focus- {
        background: #F8B2D0;
      }
    }
  }

}

.flip-article-head {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: rem(60) 0;

  @include tablets-down() {}

  &__btn {
    background: #FEDEE9;
    border: rem(1) solid #FEDAE6;
    box-sizing: border-box;
    border-radius: rem(100);
    font-weight: bold;
    font-size: rem(16);
    line-height: 160%;
    text-align: center;
    color: #E497B4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 rem(26);
    height: rem(65);
    width: rem(190);
    position: relative;

    @include tablets-down() {
      font-size: rem(14);
      width: rem(140);
      height: rem(45);
      padding: 0 rem(16);
    }

    &:before {
      content: "";
      display: block;
      width: rem(8);
      height: rem(11);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    &.prev {
      padding-left: rem(36);

      @include tablets-down() {
        order: 2;
        padding-left: rem(26);
      }

      &:before {
        left: rem(26);
        background: url("../img/icons/arrow-prev-purple.svg") no-repeat 50% / contain;
        
        @include tablets-down() {
          left: rem(15);
        }
      }
    }

    &.next {
      padding-right: rem(36);

      @include tablets-down() {
        order: 3;
        padding-right: rem(26);
      }

      &:before {
        right: rem(26);
        background: url("../img/icons/arrow-next-purple.svg") no-repeat 50% / contain;
        
        @include tablets-down() {
          right: rem(15);
        }
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(36);
    line-height: 130%;
    text-align: center;
    color: #60355A;

    @include tablets-down() {
      width: 100%;
      order: 1;
      margin-bottom: rem(25);
    }
  }
}

.calcualator-input {
  position: relative;

  input {
    font-family: inherit;
    background: #FFFFFF;
    border: rem(2) solid #FEDAE6;
    border-radius: rem(100);
    height: rem(54);
    width: 100%;
    padding-right: rem(60);
    padding-left: rem(26);
    border: none;
    outline: none;
    font-weight: bold;
    font-size: rem(18);
    color: #242B45;
    flex-grow: 1;

    &::placeholder { 
      opacity: 1;
      color: #E5CAE2;
    }
  }

  &__units {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(20);
    font-weight: bold;
    font-size: rem(17);
    line-height: 130%;
    color: #F9AFC9;
  }
}