.articles {
  &__head {
    padding-bottom: rem(80);
    position: relative;
  
    @include phones-lg-down() {
      padding-bottom: 50px;
    }
  
    &:before {
      content: "";
      display: block;
      width: 100vw;
      height: rem(1000);
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      background-color: #FFFBFC;
      pointer-events: none;
      z-index: -1;
    }

    .main-title {
      margin: 0 0 rem(40);
  
      @include phones-lg-down() {
        margin: 0 0 rem(22);
      }
    }
  }
  &__list {
    padding-top: rem(67);
    padding-bottom: rem(115);
  
    @include phones-lg-down() {
      padding-top: rem(30);
      padding-bottom: rem(70);
    }
  
    .article-card {
      height: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-25) rem(-45);
  }
  
  &__col {
    padding: 0 rem(25);
    margin-bottom: rem(45);
    width: 33.333%;
  
    @include tablets-down() {
      width: 50%;
    }
  
    @include phones-lg-down() {
      width: 100%;
    }
  }
  
}


.articles-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-right: -15px;

  @include phones-lg-down() {
    margin-bottom: rem(-12);
    margin-right: rem(-12);
  }

  li {
    margin-right: rem(15);
    margin-bottom: 15px;
    // filter: drop-shadow(rem(17) rem(21) rem(41) rgba(92, 117, 214, 0.26));
    
    @include phones-lg-down() {
      margin-right: rem(12);
      margin-bottom: rem(12);
    }
  }

  a {
    border-radius: rem(100);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: rem(16);
    line-height: 160%;
    color: #60355A;
    display: flex;
    align-items: center;
    border-radius: rem(30);
    height: rem(55);
    padding: 0 rem(31);
    border: rem(2) solid #FEDAE6;
    transition: all .3s;
    filter: drop-shadow(17px 21px 41px rgba(92, 117, 214, 0.26));

    @include phones-lg-down() {
      height: rem(46);
      padding: 0 rem(20);
      font-size: rem(14);
    }

    &.active {
      background: #FEDAE6;
      font-weight: bold;
      filter: none;
    }

    &:hover {
      background: #FEDAE6;
      filter: none;
    }
  }
}
