
.step-hero {
  background: #FFFBFC;

  .container {
    position: relative;
  }
  
  .main-title {
    margin-bottom: rem(44);
    max-width: rem(404);

    @include phones-lg-down() {
      margin-top: 60%;
      max-width: rem(216);
      font-weight: 400;
      margin-bottom: rem(16);
      font-size: rem(27);
      line-height: 130%
    }
  }

  &__desc {
    max-width: rem(500);
    margin-bottom: rem(45);
    font-size: rem(18);
    line-height: 160%;
    color: #242B45;

    @include phones-lg-down() {
      font-size: rem(15);
      line-height: rem(21);
      margin-bottom: 0;
    }
  }

  &__emblem {
    width: rem(179);
    display: block;
    position: absolute;
    top: rem(180);
    right: rem(-130);

    @include desktop-lg-down() {
      right: rem(-60);
    }

    @include desktop-down() {
      right: rem(20);
    }

    @include tablets-down() {
      width: rem(80);
      top: auto;
      bottom: 0;
    }

    @include phones-lg-down() {
      top: 28%;
    }
  }

  &__down-link {
    font-family: "Montserrat";
    height: rem(70);
    padding: 0 rem(48);

    @include tablets-down() {
      font-size: 0;
      padding: 0;
      width: rem(49);
      height: rem(49);
      position: relative;
      margin-top: rem(25);


      &:after {
        content: '';
        display: block;
        width: rem(16);
        height: rem(17);
        position: absolute;
        background: url("../img/icons/arrow-down-red.svg") no-repeat 50% / contain;
      }
    }

  }

  &--become-mom {
    padding-bottom: rem(123);
    background: url("../img/images/step-hero-become-mom.png") no-repeat calc(50vw - #{rem(240)}) 0% / rem(1703), #FFFBFC;
    
    @include phones-lg-down() {
      padding-bottom: rem(20);
      background: url("../img/images/step-hero-become-mom-mob.png") no-repeat 50% rem(50) / contain, #FFFBFC;
    }
  }
}

.elements-life {
  
  &__cover {
    position: relative;
    padding-top: rem(60);
  }

  &__content {
    position: relative;
    z-index: 2;
  }

  .main-title {
    font-size: rem(54);
    max-width: rem(480);
    margin-bottom: rem(70);

    @include tablets-down() {
      margin-bottom: rem(34);
      font-size: rem(27);
      line-height: 100%;
      
    }

    sup {
      font-size: rem(24);
      line-height: 130%;
      top: em(-16);

      @include tablets-down() {
        font-size: rem(18);
        top: em(-10);
      }
    }
  }

  &__subtitle {
    font-weight: 300;
    font-size: rem(36);
    line-height: 100%;
    color: #DE0052;
    display: block;
    margin-top: rem(24);

    @include tablets-down() {
      margin-top: rem(15);
      font-size: rem(16);
      font-weight: 500;
    }
  }

  &__properties-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-15) rem(-45);
    max-width: rem(710);

    @include tablets-down() {
      margin-bottom: rem(-15);
    }
    
  }

  &__properties-col {
    padding: 0 rem(15);
    width: 50%;
    margin-bottom: rem(45);

    @include tablets-down() {
      width: 100%;
      margin-bottom: rem(15);
    }
  }

  &--become-mom {
    padding-bottom: rem(177);

    @include tablets-down() {
      padding-bottom: rem(45);
    }

    .elements-life__cover {
      margin-bottom: rem(133);

      @include tablets-down() {
        padding-top: rem(360);
        margin-bottom: rem(45);
      }
    }
  }

  &__btns {
    display: flex;

    .btn {
      margin-right: 60px;
      
      &:last-child {
        margin-right: 0;
      }
    }
  }


  &__elements {

    @include tablets-down() {
      width: 100%;
      position: relative;
    }
  
    &--become-mom {
      top: rem(-5);
      left: rem(443);
      position: absolute;
      width: rem(1370);
      height: rem(982);
      background: url("../img/images/elements-life/spot.svg") no-repeat 50% / contain;
      
      @media (max-width: 1360px) {
        left: auto;
        right: rem(-350);
        
      }

      @include tablets-down() {
        top: rem(28);
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        width: rem(794);
        height: rem(570);
      }

      .elements-life__product {
        width: rem(530);
        position: absolute;
        left: 384px / 1370px * 100%;
        top: 53px / 982px * 100%;

        @include tablets-down() {
          width: rem(328);
          top: -56px / 570px * 100%;
          left: 209px / 794px * 100%;
        }
      }

      .elements-life__socks {
        position: absolute;
        left: 763px / 1370px * 100%;
        top: (281px + 75px) / 982px * 100%;
        width: rem(254);

        @include tablets-down() {
          width: rem(115);
          left: 460px / 794px * 100%;
          top: (54px + 50px) / 570px * 100%;
        }

        img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .elements-life__bottle {
        width: rem(153);
        position: absolute;
        top: (64px + 75px) / 982px * 100%;
        left: 369px / 1370px * 100%;

        @include tablets-down() {
          width: rem(75);
          left: 209px / 794px * 100%;
          top: (0px + 50px) / 570px * 100%;
        }

        img {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .elements-life__reward {
        width: rem(94);
        position: absolute;
        top: 528px / 982px * 100%;
        left: 860px / 1370px * 100%;

        @include tablets-down() {
          width: rem(54);
          top: 217px / 570px * 100%;
          left: 500px / 794px * 100%;
        }
      }
    }
  }

}

.useful-articles-slider--become-mom-elements {
  &:before {
    background: linear-gradient(-270deg, #FFFFFF 85.96%, rgba(255, 242, 245, 0) 100%);
    @include tablets-down() {
      display: none;
    }
  }

  &:after {
    background: linear-gradient(270deg, #FFFFFF 85.96%, rgba(255, 242, 245, 0) 100%);
    @include tablets-down() {
      display: none;
    }
  }

  .swiper-button {
    @include tablets-down() {
      display: none;
    }
  }
}

.elements-properties {
  display: flex;
  align-items: center;

  &__ico {
    width: rem(77);
    height: rem(77);
    position: relative;
    margin-right: rem(25);
    flex-shrink: 0;
    border: 2px solid #F9AFC9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 100%;
    }

    &--kubs {
      img {
        width: rem(35);
      }
    }
  }
  
  > div {
    font-weight: 500;
    font-size: rem(16);
    line-height: 130%;
    color: #60355A;
  }
}

.elem-card {
  background: #FFFFFF;
  box-shadow: rem(0) rem(4) rem(24) #F9E5E9;
  border-radius: rem(10);
  padding: rem(34) rem(28) rem(75);
  display: flex;
  flex-direction: column;
  height: 100%;

  @include tablets-down() {
    padding: rem(15) rem(16) rem(60) rem(24);
  }

  &__ico {
    width: rem(78);
    height: rem(78);
    border-radius: 50%;
    margin-bottom: rem(45);
    font-family: 'Raleway';
    font-style: normal;
    font-weight: bold;
    font-size: rem(48);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: rem(20);
    padding-top: rem(5);

    @include tablets-down() {
      width: rem(56);
      height: rem(56);
      padding-top: rem(25);
      padding-left: rem(25);
    }

    sub {
      font-size: rem(18);
      bottom: em(-2);
    }

    img {
      width: 100%;
    }

    &--vit-b9 {
      background: #E9F8E4;
      color: #93C582;
    }

    &--vit-i {
      background: #FEDAE6;
      color: #FA5995;
    }

    &--vit-omega3 {
      background: #FFE3CF;
      color: #FF8731;
    }

    &--vit-d3 {
      background: #FFEDC8;
      color: #FFBF3F;
    }

    &--vit-e {
      background: #DBF4FF;
      color: #6CB8DB;
    }

  }

  &__title {
    margin-bottom: rem(25);
    min-width: rem(45);
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(22);
    color: #60355A;
  }

  &__desc {
    font-size: rem(16);
    line-height: 140%;
    color: #60355A;
    flex-grow: 1;

    @include tablets-down() {
      font-size: rem(14);
    }
  }
}

.useful-articles--success-criteria {
  padding-top: rem(100);
  padding-bottom: rem(62);
  counter-reset: section;

  @include tablets-down() {
    padding-top: rem(34);
  }

  .section-small-title {
    @include tablets-down() {
      margin-bottom: rem(52);
    }
  }

  .useful-articles__actions {
    @include tablets-down() {
      margin-top: rem(80);
      margin-bottom: rem(85);
    }
  }

  .uses-acco {
    margin-top: rem(95);
  }

  .uses-acco-item__head {
    @include tablets-down() {
      align-items: center;
    }
  }

  .useful-articles-cards .article-card:not(:last-child) {
    @include tablets-down() {
      margin-bottom: rem(20);
    }
  }

  .article-card__img {
    position: relative;
    
    &::before,
    .num {
      counter-increment: section;  
      content: "0"counter(section);
      width: rem(49);
      height: rem(49);
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      position: absolute;
      top: rem(24);
      left: rem(28);
      font-family: "Raleway";
      font-style: normal;
      font-weight: bold;
      font-size: rem(18);
      line-height: 130%;
      color: #F9AFC9;
      border-radius: 50%;
      z-index: 2;

      @include tablets-down() {
        top: rem(20);
        left: rem(17);
      }
    }

    
  }
}

