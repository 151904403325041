@import "_media.scss";

$pass-icons: "../img/icons/";

* strong {
	font-weight: 900;
}

.hide-phone-lg {
  @media screen and (max-width: 575.98px) {
    display: none !important;
  }
}

.show-phone-lg {
  @media screen and (min-width: 576px) {
    display: none !important;
  }
}

.hide-tablet {
  @media screen and (max-width: 767.98px) {
    display: none !important;
  }
}

.show-tablet {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.nowrap {
  white-space: nowrap;
}

.hide {
  display: none !important;
}

.pink-wrapper {
  background: #FFFBFC;
}

.white-wrapper {
  background: $white;
}

.revers-block {
  transform: scaleX(-1);
}

.section-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  @include fz(64, 64);
  color: #242B45;

  &--sm {
    @include fz(54);
  }

  @include desktop-down() {
    @include fz(54);
  }

  @include tablets-down() {
    @include fz(40);
    font-weight: 400;
  }

  @include phones-lg-down() {
    @include fz(27, 32);
  }

  small {
    display: block;
    font-weight: 300;
    @include fz(36, 36);
    color: #DE0052;
    margin-top: rem(20);

    @include desktop-down() {
      @include fz(30);
    }

    @include tablets-down() {
      @include fz(20);
      font-weight: 500;
    }

    @include phones-lg-down() {
      @include fz(16);
    }
  }
}

.section-small-title {
  font-weight: bold;
  @include fz(36, 47);
  color: #60355A;

  @include phones-lg-down() {
    @include fz(24);
  }
}

.btn {
  $btn: &;
  font-family: 'Raleway';
  font-weight: bold;
  font-size: rem(16);
  height: rem(60);
  border-radius: rem(110);
  transition: background $tr-hover, color $tr-hover, border $tr-hover;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 rem(24);
  border: none;
  outline: none;

  &__toggle {
    &-active {
      display: none;
    }
  }

  &--toggle {
    #{$btn}__toggle {
      &-unactive {
        display: none;
      }

      &-active {
        display: inline;
      }
    }
  }
}

.btn-fill-blue {
  background: #5C75D6;
  box-shadow: rem(17) rem(21) rem(41) rgba(92, 117, 214, 0.26);
  color: $white;

  &:hover {
    background: #415DC8;
  }
}

.btn-fill-pink {
  background: #FEDEE9;
  box-shadow: rem(17) rem(21) rem(41) rgba(222, 0, 82, 0.17);
  color: #DE0052;

  &:hover {
    background: #FCD7E4;
  }
}

.btn-fill-pink-light {
  background-color: #F8B2D0;
  box-shadow: rem(14) rem(14) rem(12) rgba(248, 178, 208, 0.2);
  color: $white;

  &:hover {
    background-color: #FCD7E4;
    color: #DE0052;
  }
}

.btn-fill-pink-light-2 {
  background-color: #F9AFC9;
  color: $white;

  &:hover {
    background-color: #FCD7E4;
    color: #DE0052;
  }
}

.btn-fill-white {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #DE0052;

  &:hover {
    background-color: #FFFFFF;
    color: #DE0052;
  }
}

.btn-border-pink-light {
  background: none;
  color: #F9AFC9;
  border: 2px solid #F9AFC9;

  &:hover {
    border-color: #D086A0;
    color: #D086A0;
  }
}

.socials {
  & > ul {
    display: flex;
    align-items: center;

    & > li {
      &:not(:last-child) {
        margin-right: rem(12);
      }

      & > a {
        display: block;
        width: rem(27);
        height: rem(27);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.offset-header {
  padding-top: rem($header-h);

	@include tablets-down() {
		padding-top: rem($header-h-m);
	}
}

.breadcrumbs {
  display: inline-block;
  max-width: 100%;
  margin-bottom: rem(40);
  margin-top: rem(40);

  @include phones-lg-down() {
    margin: 0 rem(-20) rem(30);
    padding: rem(12) rem(20);
    background-color: $white;
    display: block;
    max-width: none;
  }

  ul {
    display: block;
    white-space: nowrap;
    background: #FCF1F4;
    border-radius: rem(100);
    padding: rem(5) rem(15);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #F9AFC9;

    @include phones-lg-down() {
      background-color: #fff;
      padding: 0;
    }

    li {
      position: relative;
      font-weight: bold;
      @include fz(12, 16);
      color: #F9AFC9;
      display: inline;

      &:not(:last-child) {
        padding-right: rem(10);

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: rem(4);
          margin-top: rem(-1);
          width: rem(2);
          height: rem(2);
          border-radius: 50%;
          background-color: #F9AFC9;
        }
      }

      a {
        color: inherit;

        &:hover {
          color: #60355A;
        }
      }
    }
  }

  &--invert {
    ul {
      background-color: #fff;
      li {
        color: #F9AFC9;
      }
    }
  }
}

.main-title {
  font-weight: 300;
  font-size: rem(64);
  line-height: 100%;
  color: #242B45;
  margin: 0;

  @include tablets-down() {
    @include fz(40);
  }

  @include phones-lg-down() {
    @include fz(36);
  }
}

.file-download {
  display: inline-flex;
  align-items: center;
  padding: rem(16) rem(16) rem(16) rem(30);
  background: #DE0052;
  box-shadow: rem(17) rem(21) rem(41) rgba(222, 0, 82, 0.17);
  border-radius: rem(5);
  font-weight: 400;
  @include fz(16, 22);
  color: $white;
  transition: background $tr-hover, box-shadow $tr-hover;

  &:hover {
    background: #D00651;
    box-shadow: rem(17) rem(21) rem(41) rgba(222, 0, 82, 0);
  }

  @include phones-lg-down() {
    @include fz(15);
  }

  &__ico {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem(24);
    width: rem(67);
    height: rem(67);
    min-width: rem(67);
    border-radius: 50%;
    background-color: $white;

    @include phones-lg-down() {
      width: rem(50);
      height: rem(50);
      min-width: rem(50);
      margin-right: rem(32);
    }

    img {
      width: 70%;
      height: 70%;
    }
  }

  strong {
    font-weight: bold;
  }
}

.uses-acco {
  &-item {
    background: #FFF3F7;
    border-radius: rem(5);
    overflow: hidden;

    &__head {
      padding: rem(15) rem(41) rem(15) rem(45);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: bold;
      @include fz(16, 26);
      color: #60355A;
      cursor: pointer;
      transition: color $tr-hover;

      &:hover {
        color: #DE0052;
      }

      @include phones-lg-down() {
        padding: rem(15) rem(20);
        align-items: flex-start;
      }

      .btn {
        margin-left: auto;
        height: rem(55);
        min-width: rem(200);
        text-align: center;

        @include tablets-down() {
          display: none;
        }
      }

      u {
        text-decoration: none;
        border-bottom: rem(1) solid #FEDAE6;
      }
    }

    &__ico {
      width: rem(57);
      height: rem(57);
      min-width: rem(57);
      margin-right: rem(30);

      @include phones-lg-down() {
        width: rem(38);
        height: rem(38);
        min-width: rem(38);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__body {
      display: none;
    }

    &__content {
      padding: rem(30) rem(41) rem(59) rem(135);

      @include tablets-down() {
        padding: rem(30) rem(41) rem(59) rem(41);
      }

      @include phones-lg-down() {
        padding: rem(15) rem(20);
      }

      & > ul {
        & > li {
          font-weight: 400;
          @include fz(14, 18);
          color: #60355A;

          &:not(:last-child) {
            margin-bottom: rem(20);
          }

          strong {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.useful-articles {
  overflow: hidden;
  padding: rem(90) 0 rem(85);

  @include tablets-down() {
    padding: rem(65) 0 rem(70);
  }

  .section-small-title {
    text-align: center;
    margin-bottom: rem(90);

    @include phones-lg-down() {
      margin-bottom: rem(69);
    }
  }

  &-slider {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -10%;
      right: 84%; /* (28% * 3) 3 slides in views 28% */
      pointer-events: none;
      width: 100vw;
      height: 120%;
      background: linear-gradient(-270deg, #FFFBFC 85.96%, rgba(255, 242, 245, 0) 100%);
      z-index: 1;
      transition: opacity 0.1s;
    }

    &::after {
      content: "";
      position: absolute;
      top: -10%;
      left: 84%; /* (28% * 3) 3 slides in views 28% */
      pointer-events: none;
      width: 100vw;
      height: 120%;
      background: linear-gradient(270deg, #FFFBFC 85.96%, rgba(255, 242, 245, 0) 100%);
      transition: opacity 0.1s;
    }

    &.useful-articles-slider--started {
      &::before {
        opacity: 0;
      }
    }

    &.useful-articles-slider--ended {
      &::after {
        opacity: 0;
      }
    }

    .swiper-container {
      overflow: visible;
      z-index: auto;
    }

    .swiper-wrapper {
      z-index: auto;
    }

    .swiper-slide {
      width: 28%;
      height: auto;

      .article-card {
        height: 100%;
      }

      @include tablets-down() {
        width: rem(290);
      }
    }
  }

  &-cards {
    .article-card {
      &:not(:last-child) {
        margin-bottom: rem(40);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(60);

    .btn {
      width: 100%;
      max-width: rem(196);

      & + .btn {
        margin-left: rem(8);
      }
    }

    @include tablets-down() {
      margin-top: rem(70);
      margin-bottom: rem(70);
      flex-direction: column;

      .btn {
        width: 100%;
        max-width: rem(274);

        & + .btn {
          margin-top: rem(24);
        }
      }
    }
  }
}

.article-card {
  display: block;
  &__inner {
    background: #FFFFFF;
    box-shadow: 0 rem(4) rem(24) #F9E5E9;
    border-radius: rem(10);
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all .3s;
  }

  &:hover {
    .article-card__inner {
      box-shadow: 0 rem(-6) rem(24) #F9E5E9;
      // box-shadow: none;
    }
    .article-card__link {
      color: #DE0052;
    }
  }

  &__img {
    width: 100%;
    height: 0;
    padding-top: 49.2%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
    }
  }

  &__body {
    padding: rem(27) rem(27) rem(32);
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include tablets-down() {
      padding: rem(33) rem(15) rem(30);
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: rem(18);

    .tag {
      margin-bottom: rem(6);
      margin-right: rem(6);
    }
  }

  &__title {
    font-weight: bold;
    @include fz(18, 22);
    color: #242B45;
    margin: 0 0 rem(18);
  }

  &__desc {
    flex-grow: 1;
    font-weight: 400;
    @include fz(16, 21);
    color: #60355A;
    margin-bottom: rem(32);

    @include tablets-down() {
      @include fz(14);
    }
  }

  &__link {
    font-weight: bold;
    @include fz(16, 24);
    color: #60355A;

    u {
      text-decoration: none;
      border-bottom: rem(1) solid #FEDAE6;
    }

    // &:hover {
    //   u {
    //     // border-bottom-color: #60355A
    //   }
    // }
  }
}

.tag {
  display: inline-block;
  background: #FCF1F4;
  border-radius: rem(100);
  font-weight: bold;
  @include fz(12, 16);
  color: #F9AFC9;
  padding: rem(4) rem(13);

  &--lg {
    @include fz(14);
  }

  &--white {
    background-color: $white;
  }
}

.swiper-button-custom {
  width: rem(69);
  height: rem(69);
  border-radius: 50%;
  background-color: $white;
  transition: opacity 0.2s, background $tr-hover;
  box-shadow: rem(17) rem(21) rem(41) rgba(222, 0, 82, 0.17);
  z-index: 2;

  &::after {
    width: rem(8);
    height: rem(15);
    background: url("../img/icons/arrow-next-blue.svg") no-repeat 50%/100%;
    font-size: 0;
  }

  &:hover {
    background: #5C75D6;

    &::after {
      background: url("../img/icons/arrow-next-white.svg") no-repeat 50%/100%;
    }
  }

  &.swiper-button-prev {
    &:after {
      transform: rotate(180deg);
    }
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}


.uteka-widget {
  /* Минимально поддерживаемые размеры виджета: 320x520. */
  width: 100%;
  height: 768px;
  outline: 1px solid #e0e0e0;
}

.uteka-widget iframe {
  border: none;
  width: 100%;
  height: calc(100% - 57px);
}

.uteka-widget__container {
  margin: 0 auto;
  padding: 0 16px;
}

@media (min-width: 600px) {
  .uteka-widget__container {
    padding: 0 20px;
  }
}

@media (min-width: 768px) {
  .uteka-widget__container {
    padding: 0 40px;
  }
}

@media (min-width: 1024px) {
  .uteka-widget__container {
    padding: 0 44px;
  }
}

@media (min-width: 1440px) {
  .uteka-widget__container {
    padding: 0 60px;
    max-width: 1440px;
  }
}

.uteka-widget-header {
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
}

.uteka-widget-header__inner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 56px;
}

.uteka-widget-header__title {
  background: url('https://widget.uteka.ru/static/img/widgets/title_2@mobile.svg') no-repeat;
  background-position: center;
  width: 230px;
  height: 24px;
}

.uteka-widget-header__logo {
  height: 16px;
}

.uteka-widget-header__logo img {
  height: 100%;
}

.uteka-widget-header__close {
  position: absolute;
  top: 0;
  right: -8px;
  bottom: 0;
  z-index: 5;
  margin: auto;
  width: 32px;
  height: 32px;
  background: url('https://widget.uteka.ru/static/img/widgets/close_2.svg') no-repeat center;
}

@media (min-width: 1024px) {
  .uteka-widget-header__inner {
    flex-direction: row;
  }

  .uteka-widget-header__title {
    background-image: url('https://widget.uteka.ru/static/img/widgets/title_2.svg');
    width: 264px;
    height: 24px;
  }

  .uteka-widget-header__logo {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
  }
}