/*-------------- single-news --------------*/

.main-article {
  background: linear-gradient(to bottom, #FFFBFC rem(480), #fff rem(480));
}

.article {

  &::before {
    content: "";
    width: 100%;
    height: rem(632);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFBFC;

    @include phones-lg-down() {
      height: calc(#{rem(360)} - 3%);
    }
  }

  & > .container {
    position: relative;
    z-index: 2;
  }

  @include phones-lg-down() {
    position: relative;
  }

  &__head {
    background-color: #FFFBFC;

    + .container {
      .article__content {
        padding-top: rem(75);
      }
    }
  }
  &__inner {
    width: 100%;
    max-width: rem(1200);
    margin: 0 auto;
    padding: 0 0 rem(80);

    @include tablets-down() {
      padding: 0 0 rem(67);
    }

    img {
      max-width: 100%;
    }


    h1 {
      font-weight: 300;
      @include fz(48, 62);
      text-align: center;
      color: #242B45;
      width: 100%;
      max-width: rem(940);
      margin: 0 auto rem(48);

      @include tablets-down() {
        @include fz(40);
      }

      @include phones-lg-down() {
        @include fz(27);
        margin: 0 0 rem(18);
        text-align: left;
        width: 100%;
        max-width: rem(320);
      }
    }

    & > img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__content {
    padding: rem(48) rem(100) 0;
    font-weight: 500;
    @include fz(16, 26);
    color: #613768;

    @include tablets-down() {
      padding: rem(55) 0 0;
    }

    @include phones-lg-down() {
      @include fz(15);
    }

    & > img {
      width: 100%;
      height: auto;
      display: block;
    }

    strong {
      font-weight: bold;
    }

    p {
      margin: rem(45) 0;

      @include phones-lg-down() {
        margin: rem(25) 0;
      }
    }

    & > h2 {
      font-weight: 300;
      @include fz(48, 62);
      color: #DE0052;
      margin: rem(45) 0;

      @include tablets-down() {
        @include fz(40);
      }

      @include phones-lg-down() {
        @include fz(24);
      }
    }

    & > ul {
      margin: rem(45) 0 rem(75);

      @include phones-lg-down() {
        margin: rem(45) 0;
      }

      li {
        width: 100%;
        max-width: rem(800);
        font-weight: 500;
        @include fz(18, 29);
        color: #613768;
        position: relative;
        padding-left: rem(80);

        @include tablets-down() {
          padding-left: rem(44);
        }

        @include phones-lg-down() {
          @include fz(16);
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: rem(37);
          height: rem(37);
          background: url("../img/icons/ulico.svg") no-repeat 50%/100%;
          box-shadow: rem(17) rem(21) rem(41) rgba(222, 0, 82, 0.17);
          border-radius: 50%;

          @include tablets-down() {
            width: rem(30);
            height: rem(30);
          }
        }

        &:not(:last-child) {
          margin-bottom: rem(18);
        }

        strong {
          font-weight: bold;
        }
      }
    }

    blockquote {
      background: #FEDAE6;
      box-shadow: rem(11) rem(13) rem(49) rgba(255, 225, 236, 0.52);
      padding: rem(40) rem(58) rem(40) rem(50);
      border-left: rem(12) solid #DE0052;
      margin: rem(70) 0 rem(56);

      @include tablets-down() {
        padding: rem(23);
        border-left: rem(4) solid #DE0052;
      }

      @include phones-lg-down() {
        margin: rem(45) 0 rem(29);
      }
    }

    .file-download {
      margin-bottom: rem(82);

      @include tablets-down() {
        margin-bottom: 0;
      }

      @include phones-lg-down() {
        width: 100%;

        &__label {
          strong {
            display: block;
          }
        }
      }
    }
  }

  table:not(.fc-scrollgrid):not(.fc-scrollgrid-sync-table):not(.fc-col-header) {
    font-weight: 500;
    font-size: rem(14);
    line-height: 130%;
    border-collapse: collapse;
    width: 100%;
    margin: rem(30) 0;

    @include tablets-down() {
      min-width: rem(700);
    }

    td, th {
      border: rem(2) solid #FEDAE6;
      height: rem(65);
      text-align: center;
      vertical-align: middle;
      padding: 0 rem(15);
    }

    thead {
      tr {
        th {
          font-weight: bold;
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            font-weight: bold;
            width: rem(141);
          }
        }
      }
    }

    .gray-bg {
      background: #FEDAE6;
    }

    .gray-light-bg {
      background: #FEF5F6;
    }

    .small-h {
      height: rem(36);
    }

    small {
      font-size: rem(12);
      line-height: rem(15.6);
    }
  }
}

.table-wrap {
  @include tablets-down() {
    overflow-x: auto;
  }
}

.article2 {
  &:before {
    height: rem(530);
  }

  .article__content {
    padding-top: rem(100);

    @include tablets-down() {
      padding-top: rem(50);
    }
  }
}