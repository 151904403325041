@import "_media.scss";

.mob-menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFDFD;
  padding-top: rem($header-h-m);
  z-index: 10;
  transition: transform $tr-duration ease-in-out;

  @include tablets-up() {
    display: none;
  }

  &.open {
    transform: translateY(100%);
    transition-delay: 0.2s;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-btn {
    $mob-menu-btn: &;
    width: 64px;
    height: 60px;
    background: #5C75D6;
    border: none;
    cursor: pointer;
    margin-right: rem(-20);
    margin-left: auto;
    outline: none;
    padding: 0 18px;
    transition: background $tr-duration;

    &.active {
      background: #DE0052;

      #{$mob-menu-btn}__inner {
        & > span {
          &:nth-child(1){
            top: 8px;
            transform: rotate(45deg);
          }
          &:nth-child(2){
            transform: scale(0);
          }
          &:nth-child(3){
            bottom: 8px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    @include tablets-up() {
      display: none;
    }

    &__inner {
      height: 19px;
      width: 28px;
      position: relative;
      display: block;

      & > span {
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: $white;
        border-radius: 20px;
        left: 0;
        display: inline-block;
        transition: all $tr-duration;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 50%;
          margin-top: -1.5px;
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
  }

  &-pay-btn {
    width: 100%;
    max-width: rem(200);
  }

  &-nav {
    width: 100%;
    margin-bottom: rem(34);

    & > ul {
      & > li {
        border-bottom: rem(3) solid #FEF5F6;

        & > a {
          display: block;
          padding: rem(32) 0;
          font-weight: bold;
          @include fz(18, 20);
          text-align: center;
          color: #60355A;
        }
      }
    }
  }

  &__disclamer {
    background: #FEF5F6;
    padding: rem(14) rem(12);
    font-weight: bold;
    @include fz(12, 16);
    text-align: center;
    color: #F9AFC9;
  }
}

.mob-acco {
  &__item {
    &-head {
      display: block;
      padding: rem(32) 0;
      font-weight: bold;
      @include fz(18, 20);
      text-align: center;
      color: #60355A;
      cursor: pointer;

      &.active {
        .mob-acco__item-title {
          &::after {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &-body {
      display: none;
    }

    &-title {
      padding-right: rem(30);
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: rem(-3.5);
        width: rem(14);
        height: rem(7);
        background: url("../img/icons/arrow-down-blue.svg") no-repeat 50%/100%;
        transition: transform $tr-duration;
      }
    }

    &-content {
      & > ul {
        & > li {
          &:not(:last-child) {
            border-bottom: rem(3) solid $white;
          }

          & > a {
            display: block;
            padding: rem(16) 0;
            background: #FEF5F6;
            font-weight: bold;
            @include fz(16, 20);
            text-align: center;
            color: #60355A;
          }
        }
      }
    }
  }
}
