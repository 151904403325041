.article3 {
  &::before {
    height: rem(450);
  }

  h1 {
    text-align: left;
    margin-left: 0;
    margin-bottom: rem(140);

    @include tablets-down() {
      margin-bottom: rem(80);
    }
  }
}

.reviews-block {

  &__row {
    margin: 0 rem(-15);

    &:after {
      content: " ";
      clear: both;
      display: table;
    }
  }
  &__col {
    width: 50%;
    padding: 0 rem(15);
    margin-bottom: rem(30);

    @include tablets-down() {
      width: 100%;
    }

    &:nth-child(even) {
      float: right;

      @include tablets-down() {
        float: none;
      }
    }

    &:nth-child(odd) {
      float: left;

      @include tablets-down() {
        float: none;
      }
    }
  }

  &__btn {
    display: flex;
    width: 100%;
    max-width: rem(300);
    margin: 0 auto;
    margin-top: rem(80);

    @include tablets-down() {
      margin-top: rem(50);
    }
  }
}

.review-card {
  background: #FFFFFF;
  box-shadow: rem(0) rem(4) rem(24) #F9E5E9;
  border-radius: rem(10);
  padding: rem(50);

  @include tablets-down() {
    padding: rem(35) rem(20);
  }

  &__name {
    font-weight: bold;
    font-size: rem(18);
    line-height: rem(22);
    color: #242B45;
    margin-bottom: rem(20);
  }

  &__stars {
    display: flex;
    margin-bottom: rem(23);

    img {
      margin-right: rem(9);
    }
  }

  &__text {
    font-size: rem(16);
    line-height: 130%;
    color: #60355A;
    margin-bottom: rem(30);
  }

  &__link {
    font-weight: bold;
    font-size: rem(16);
    line-height: 130%;
    color: #60355A;
    display: inline-block;
    border-bottom: rem(1) solid #FEDAE6;

    span {
      color: #DE0052;
    }
  }

}