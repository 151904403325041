.step-hero--was-born {
  padding-bottom: rem(76);
  background: url("../img/images/step-hero-was-born.png") no-repeat calc(50vw - #{rem(340)}) 100% / rem(1460), #FFFBFC;
  
  @media (max-width: 1200px) {
    background-position: calc(50vw - #{rem(500)}) 0;
  }

  @include phones-lg-down() {
    padding-bottom: rem(20);
    background: url("../img/images/step-hero-was-born-mob.png") no-repeat 100% rem(40) / contain, #FFFBFC;
  }
}


.elements-life--was-born {
  padding-bottom: rem(81);

  @include tablets-down() {
    padding-bottom: rem(45);
  }

  .elements-life__cover {
    margin-bottom: rem(144);
    padding-top: rem(98);

    @include tablets-down() {
      padding-top: rem(360);
      margin-bottom: rem(30);
    }
  }
}

.elements-life__elements--was-born {
  top: rem(-25);
  left: rem(518);
  position: absolute;
  width: rem(1101);
  height: rem(988);
  background: url("../img/images/elements-life/spot-2.svg") no-repeat 50% / contain;

  @media (max-width: 1360px) {
    left: auto;
    right: rem(-180);
  }

  @include tablets-down() {
    top: rem(-60);
    left: 36%;
    right: auto;
    transform: translateX(-50%);
    width: rem(597);
    height: rem(536);
  }

  .elements-life__product {
    width: rem(537);
    position: absolute;
    left: 350px / 1101px * 100%;
    top: 104px / 988px * 100%;

    @include tablets-down() {
      width: rem(291);
      top: 57px / 536px * 100%;
      left: 190px / 597px * 100%;
    }
  }

  .elements-life__bottle-2 {
    position: absolute;
    left: 769px / 1101px * 100%;
    top: (152px + 75px) / 988px * 100%;
    width: rem(181);

    @include tablets-down() {
      width: rem(99);
      left: 417px / 597px * 100%;
      top: (83px + 50px) / 536px * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__duck-toy  {
    width: rem(211);
    position: absolute;
    top: (425px + 75px) / 988px * 100%;
    left: 230px / 1101px * 100%;

    @include tablets-down() {
      width: rem(114);
      left: 125px / 597px * 100%;
      top: (231px + 50px) / 536px * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__toy-cube {
    width: rem(191);
    position: absolute;
    top: (100px + 75px) / 988px * 100%;
    left: 321px / 1101px * 100%;

    @include tablets-down() {
      width: rem(117);
      left: 174px / 597px * 100%;
      top: (54px + 50px) / 536px * 100%;
    }

    img {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .elements-life__reward {
    width: rem(94);
    position: absolute;
    top: 586px / 988px * 100%;
    left: 796px / 1101px * 100%;

    @include tablets-down() {
      width: rem(55);
      top: 334px / 536px * 100%;
      left: 442px / 597px * 100%;
    }
  }
}