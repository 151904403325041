.elements-life--about-product {
  padding-bottom: 0;
  margin-bottom: rem(130);
  
  @include tablets-down() {
    margin-bottom: rem(40);
  }

  .main-title {
    font-size: rem(64);
    max-width: rem(615);

    strong {
      font-weight: 500;
    }

    @include tablets-down() {
      text-align: center;
      max-width: 100%;
      @include fz(27);
      line-height: 120%;
      min-height: rem(420);
    }
  }

  .elements-life__subtitle {
    @include tablets-down() {
      text-align: center;
      max-width: 100%;
      font-size: rem(16);
      margin-top: rem(8);
    }
  }

  .elements-life__cover {
    margin-bottom: rem(75);
    padding-top: rem(227);

    @include tablets-down() {
      padding-top: rem(90);
      margin-bottom: rem(35);
    }
  }

  .step-hero__emblem {
    top: rem(150);
    right: rem(-48);

    @include tablets-down() {
      top: rem(40);
      right: rem(-20);
    }
  }
}

.elements-life__elements--about-product {
  top: rem(40);
  left: rem(810);
  position: absolute;
 
  @media (max-width: 1360px) {
    left: auto;
    right: rem(80);
  }

  @include tablets-down() {
    width: rem(281);
    top: rem(190);
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    
  }

  .elements-life__product {
    width: rem(643);

    @include tablets-down() {
      width: rem(281);
    }
  }


  .elements-life__reward {
    width: rem(94);
    position: absolute;
    top: 574px / 855px * 100%;
    right: -85px / 657px * 100%;

    @include tablets-down() {
      width: rem(78);
      top: 219px / 367px * 100%;
      right: -24px / 276px * 100%;
    }
  }
}

.about-video {
  margin-top: rem(130);
  margin-bottom: rem(165);

  @include tablets-down() {
    margin-top: rem(40);
    margin-bottom: rem(58);
  }
}

.video-wrap {
	width: 100%;
  padding-top: 45%;
  position: relative;

  @include tablets-down() {
    padding-top: 80%;
  }
}

.video-bg {
	width: 100%;
	height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-bg .play {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(178);
  height: rem(178);
  border-radius: 50%;
  background-color: #fff;
	position: absolute;
	top: 50%;
  left: 50%;
  padding-left: rem(15);
  transform: translate(-50%, -50%);
  box-shadow: 0 rem(4) rem(24) #F9E5E9;
  transition: .3s;

  @include tablets-down() {
    width: rem(73);
    height: rem(73);
    padding-left: rem(8);
  }

  img {
    width: rem(54);
    align-items: center;

    @include tablets-down() {
      width: rem(25);
    }
  }
}

.video-bg .play:hover {
  transform: translate(-50%, -50%) scale(1.15);
}

.video-bg > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50%;
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	pointer-events: none;
  opacity: 0;
  visibility: hidden;

	&.visible {
		opacity: 1;
    pointer-events: auto;
    visibility: visible;
	}

}

.importance-block {
  margin-bottom: rem(65);
  margin-top: rem(165);

  @include tablets-down() {
    margin-top: rem(58);
    margin-bottom: rem(37);
  }

  &__cover {
    display: flex;
    margin: 0 rem(-63) rem(70);
    flex-wrap: wrap;

    @include tablets-down() {
      flex-flow: column;
      margin-bottom: rem(40);
    }
  }

  &__img {
    padding-top: 46.8%;
    position: relative;
    overflow: hidden;
    
    img {
      position: absolute;
      width: 100%;
      top: rem(130);
      left: 0;

      @include tablets-down() {
        top: rem(-40);
      }
    }
  }

  &__col {
    padding: 0 rem(63);

    &:first-child {
      width: 45.5%;

      @include tablets-down() {
        width: 100%;
      }
    }

    &:last-child {
      width: 54.5%;

      @include tablets-down() {
        width: 100%;
      }
    }
  }

  .main-title {
    font-weight: 300;
    font-size: rem(48);
    line-height: 110%;
    margin-bottom: rem(30);

    @include tablets-down() {
      font-size: rem(24);
    }
    
  }

  p {
    font-size: rem(22);
    line-height: rem(27);
    color: #60355A;
    margin-bottom: rem(25);

    @include tablets-down() {
      font-size: rem(15);
      line-height: rem(22);
      margin-bottom: rem(30);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.they-good {
  padding-bottom: rem(133);

  @include tablets-down() {
    padding-bottom: rem(60);
  }

  .main-title {
    font-weight: 300;
    font-size: rem(48);
    line-height: 110%;
    text-align: center;
    color: #60355A;
    margin-bottom: rem(105);

    @include tablets-down() {
      font-size: rem(24);
      margin-bottom: rem(37);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-22);

    @include tablets-down() {
      margin: 0 rem(-10) rem(-20);
    }

    @include phones-lg-down() {
      margin-bottom: rem(-10);
    }
  }

  &__col {
    padding: 0 rem(22);
    width: 33.333%;

    @include tablets-down() {
      width: 50%;
      margin-bottom: rem(20);
      padding: 0 rem(10);
    }

    @include phones-lg-down() {
      width: 100%;
      margin-bottom: rem(10);
    }
  }
}

.they-good-card {
  background: #FFFFFF;
  box-shadow: 0 rem(4) rem(24) #F9E5E9;
  border-radius: rem(10);
  padding: rem(110) rem(50) rem(50);
  position: relative;
  height: 100%;
  font-size: rem(18);
  line-height: rem(22);
  color: #60355A;

  @include tablets-down() {
    padding: rem(110) rem(20) rem(45) rem(22);
    font-size: rem(15);
    line-height: rem(18);
  }

  &:before {
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(35);
    height: rem(35);
    border-radius: 50%;
    font-size: rem(18);
    line-height: rem(22);
    color: #DE0052;
    position: absolute;
    top: rem(30);
    left: rem(53);
    background: #F9E5E9;

    @include tablets-down() {
      left: rem(25);
    }
  }
}

.about-section-bg {
  background: #FFF7F8;

  .about-video {
    background: linear-gradient(to bottom, #fff 40%, #FFF7F8 40%);
  }
}

.product-substance {
  overflow: hidden;

  &__cover {
    max-width: rem(658);
    padding-top: rem(150);
    padding-bottom: rem(180);
    position: relative;

    @include tablets-down() {
      padding-top: rem(345);
      padding-bottom: rem(60);
    }
  }

  &__img {
    width: rem(985);
    position: absolute;
    top: rem(-90);
    left: rem(673);

    @include tablets-down() {
      top: rem(-70);
      left: 50%;
      transform: translateX(-50%);
      width: rem(348);
    }
  }

  .main-title {
    font-weight: 300;
    font-size: rem(48);
    line-height: 110%;
    color: #27325A;
    margin-bottom: rem(80);

    @include tablets-down() {
      font-size: rem(24);
      margin-bottom: rem(40);
    }
  }

  &__sub-title {
    font-size: rem(22);
    line-height: 130%;
    color: #DE0052;
    margin-bottom: rem(53);

    @include tablets-down() {
      font-size: rem(16);
      line-height: rem(20);
      margin-bottom: rem(30);
    }
  }

  p {
    margin-bottom: rem(24);
    font-size: rem(22);
    line-height: 130%;
    color: #60355A;

    @include tablets-down() {
      font-size: rem(15);
      line-height: rem(18);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.why-block {
  background: #FFF6F8;
  padding-top: rem(75);
  padding-bottom: rem(120);

  @include tablets-down() {
    padding-bottom: rem(65);
    padding-top: rem(48);
  }

  .main-title {
    text-align: center;
    margin-bottom: rem(72);

    @include tablets-down() {
      font-size: rem(36);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-25);

    @include tablets-down() {
      margin: 0 rem(-10) rem(-10);
    }
  }

  &__col {
    padding: 0 rem(25);
    width: 33.333%;

    @include tablets-down() {
      width: 50%;
      padding: 0 rem(10);
      margin-bottom: rem(10);
    }

    @include phones-lg-down() {
      width: 100%;
    }
  }
}

.why-block-card {
  padding: rem(38) rem(54) rem(65) rem(38);
  background: #FFFFFF;
  box-shadow: 0 rem(4) rem(24) #F9E5E9;
  border-radius: rem(10);
  font-size: rem(18);
  line-height: 130%;
  color: #60355A;
  height: 100%;

  @include tablets-down() {
    padding: rem(30) rem(23) rem(55) rem(24);
    font-size: rem(15);
  }

  &__img {
    position: relative;
    margin-bottom: rem(60);
    width: rem(98);
    height: rem(98);

    &:before {
      content: "";
      background: #FEDAE6;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    img {
      display: block;
      width: 100%;
      position: relative;
      z-index: 2;
    }
  }

  strong {
    font-weight: bold;
    display: block;
    margin-bottom: rem(18);
  }
}