@import "../_media.scss";

.home-main {
  background: #FFFDFD;
}

/*-------------- hero --------------*/
.hero {
  position: relative;

  &__content {
    display: flex;
    align-items: center;

    @include desktop-lg-down() {
      align-items: flex-start;
    }

    @include tablets-down() {
      padding: rem(60) 0;
    }

    @include phones-lg-down() {
      padding: rem(30) 0 rem(50);
    }
  }

  &-content {
    position: relative;
    width: 45%;

    @include desktop-lg-down() {
      margin-top: rem(32);
    }

    @include tablets-down() {
      margin-top: 0;
      width: auto;
      z-index: 1;
    }

    .section-title {
      margin-bottom: rem(60);
      width: 100%;

      @include tablets-down() {
        max-width: rem(420);
        margin-bottom: rem(32);
      }

      @include phones-lg-down() {
        max-width: rem(300);
      }
    }

    .home-scroll-btns {
      margin-bottom: rem(80);
      position: relative;
      z-index: 1;

      @include tablets-down() {
        margin-bottom: rem(32);
      }

      @include phones-lg-down() {
        margin-bottom: rem(24);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    .btn {
      min-width: rem(200);

      &:not(:last-child) {
        margin-right: rem(40);
      }

      @include phones-lg-down() {
        height: rem(47);
        min-width: rem(144);
        font-size: rem(12);
      }
    }
  }
}

.hero-slider {
  position: relative;
  width: 55%;
  margin-left: auto;

  @include tablets-down() {
    display: none;
  }

  .swiper-container {
    z-index: auto;
    overflow: visible;
  }

  .swiper-wrapper {
    z-index: auto;
  }

  .swiper-slide {
    &.swiper-slide-duplicate-active,
    &.swiper-slide-active {
      .hero-slide__pic {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition-delay: 0.3s;
      }

      .hero-slide-decor {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        &-1-1,
        &-2-1,
        &-3-1 {
          transition-delay: 0.5s;
        }

        &-1-2,
        &-2-2,
        &-3-2 {
          transition-delay: 0.6s;
        }
      }
    }
  }

  &-btns {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: rem(200);
    right: 0;

    .swiper-button-next,
    .swiper-button-prev {
      position: static;
    }

    .swiper-button-custom {
      outline: none;
      z-index: auto;
      // background: #5C75D6;

      // &::after {
      //   background: url("../img/icons/arrow-next-white.svg") no-repeat 50%/100%;
      // }

      // &:hover {
      //   background: #DE0052;
      // }

      &:not(:last-child) {
        margin-right: rem(14);
      }

      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
  }
}

.home-label {
  position: absolute;
  top: 0;
  right: rem(-129);
  width: rem(179);
  height: rem(179);

  @include desktop-down() {
    right: 0;
    width: rem(109);
    height: rem(109);
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.hero-slide {
  $hero-slide: &;
  position: relative;
  width: 100%;

  &__bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-inner {
      width: 100%;
      height: 0;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    &--1 {
      transform: translate(-30%, -55%);
      width: 120%;

      #{$hero-slide}__bg {
        &-inner {
          padding-top: 81%;
        }
      }
    }
    &--2 {
      transform: translate(-30%, -60%);
      width: 105%;

      #{$hero-slide}__bg {
        &-inner {
          padding-top: 95.8%;
        }
      }
    }
    &--3 {
      transform: translate(-25%, -55%);
      width: 110%;

      #{$hero-slide}__bg {
        &-inner {
          padding-top: 89.46%;
        }
      }
    }
  }

  &__img {
    position: relative;
    margin: 0 0 0 auto;

    &--1 {
      // width: rem(497);
      width: 63%;
      right: rem(24);

      #{$hero-slide}__pic {
        padding-top: 162.37%;
      }
    }

    &--2 {
      // width: rem(642);
      width: 81%;
      right: rem(-76);

      #{$hero-slide}__pic {
        padding-top: 125.7%;
      }
    }

    &--3 {
      // width: rem(630);
      width: 76%;
      right: rem(-76);

      #{$hero-slide}__pic {
        padding-top: 128.1%;
      }
    }
  }

  &__pic {
    position: relative;
    width: 100%;
    height: 0;
    // overflow: hidden;
    transform: translate3d(rem(100), 0, 0);
    opacity: 0;
    transition: transform 0.7s, opacity 0.7s;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &-decor {
    position: absolute;
    opacity: 0;
    transform: translate3d(rem(100), 0, 0);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    &-1 {
      &-1 {
        // width: rem(164);
        // height: rem(176);
        width: 19.5%;
        top: 10%;
        left: 25%;
      }
      &-2 {
        // width: rem(173);
        // height: rem(172);
        width: 20.5%;
        top: 30%;
        right: -20%;
      }
    }

    &-2 {
      &-1 {
        // width: rem(123);
        // height: rem(143);
        width: 14.6%;
        top: 40%;
        left: 35%;
      }
      &-2 {
        // width: rem(152);
        // height: rem(142);
        width: 18%;
        top: 50%;
        right: -22%;
      }
    }

    &-3 {
      &-1 {
        // width: rem(100);
        // height: rem(161);
        width: 12%;
        top: 15%;
        left: 30%;
      }
      &-2 {
        // width: rem(110);
        // height: rem(184);
        width: 13%;
        top: 40%;
        right: -15%;
      }
    }

    & > img {
      width: 100%;
      height: 100%;
    }
  }
}

.home-scroll-btns {
  width: 100%;

  ul {
    display: flex;
    align-items: flex-start;

    @include tablets-down() {
      flex-direction: column;
    }

    & > li {
      &:not(:last-child) {
        padding-right: rem(40);
        margin-right: rem(40);
        border-right: rem(3) solid #F8B2D0;

        @include tablets-down() {
          padding-right: 0;
          margin-right: 0;
          border-right: 0;
          margin-bottom: rem(18);
          padding-bottom: rem(18);
          position: relative;

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: rem(38);
            height: rem(3);
            background-color: #F8B2D0;
            border-radius: rem(10);
          }
        }
      }
    }
  }
}

.home-scroll-btn {
  font-weight: bold;
  @include fz(14, 18);
  color: #613768;
  max-width: rem(170);
  display: inline-block;
  position: relative;

  &__ico {
    position: absolute;
    top: 50%;
    transition: transform $tr-duration;
    transform: scale(0);

    &--1 {
      width: rem(97);
      height: rem(71);
      left: 0;
      margin-top: rem(-71 / 2);
    }
    &--2 {
      width: rem(85);
      height: rem(67);
      margin-top: rem(-67 / 2);
    }
    &--3 {
      width: rem(75);
      height: rem(61);
      margin-top: rem(-61 / 2);
    }

    @include tablets-down() {
      display: none;
    }
  }

  &.active {
    .home-scroll-btn__ico {
      transform: scale(1);
    }
  }

  & > span {
    position: relative;
  }
}

.home-box {
  width: rem(500);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: rem((-500 / 2) + 70);

  @include tablets-down() {
    width: rem(360);
    margin-left: 0;
    left: rem(260);
    bottom: rem(-20);
    z-index: 0;
  }

  @include phones-lg-down() {
    width: rem(270);
    left: rem(120);
    // left: auto;
    // right: rem(-90);
    bottom: rem(25);
  }

  &__inner {
    padding-top: 130%;
    width: 100%;
    position: relative;
    height: 0;
  }

  &__decor {
    width: rem(165);
    position: absolute;
    bottom: rem(138);
    left: rem(16);

    @include tablets-down() {
      // width: 37.5%;
      // left: rem(-16);
      display: none;
    }
  }

  .box-elem {
    bottom: 0;
    z-index: 2;

    @include tablets-down() {
      width: 100%;
      z-index: 0;
      margin-left: 0;
      left: 0;
    }
  }
}

/*-------------- home-about --------------*/
.home-about {
  background: #FFF9FA;
  position: relative;

  &__content {
    padding-top: rem(175);
    padding-bottom: rem(200);
    position: relative;

    @include tablets-down() {
      padding: rem(50) 0 rem(70);
    }
  }

  &-content {
    width: 100%;
    max-width: rem(480);
    position: relative;
    z-index: 1;

    @include tablets-down() {
      margin-bottom: rem(36);
    }

    .section-title {
      margin-bottom: rem(93);

      @include tablets-down() {
        margin-bottom: rem(40);
        max-width: rem(360);
      }

      @include phones-lg-down() {
        margin-bottom: rem(20);
        max-width: rem(300);
      }
    }

    &__list {
      @include desktop-sm-down() {
        width: 100%;
        max-width: rem(400);
      }
      li {
        font-weight: 500;
        @include fz(18, 29);
        color: #242B45;
        padding-left: rem(30);
        position: relative;

        @include tablets-down() {
          @include fz(16);
        }

        &:not(:last-child) {
          margin-bottom: rem(8);
        }

        &::before {
          content: "";
          position: absolute;
          top: rem(8);
          left: 0;
          width: rem(10);
          height: rem(10);
          border-radius: 50%;
          background: #DE0052;
        }

        @include phones-lg-down() {
          padding-left: rem(14);

          &::before {
            top: rem(10);
            width: rem(5);
            height: rem(5);
          }
        }
      }
    }

    &__btn {
      margin-top: rem(64);
      min-width: rem(220);
    }
  }

  &-rewards {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: rem(180);
    right: 0;

    @include tablets-down() {
      position: relative;
      bottom: auto;
      right: auto;
      margin-bottom: rem(54);
    }
  }

  &-mark {
    width: rem(94);
    height: rem(91);
    margin-right: rem(40);

    @include tablets-down() {
      width: rem(68);
      height: rem(67);
      margin-right: rem(28);
    }
  }

  &-reward {
    height: rem(96);
    display: flex;
    align-items: center;
    padding: rem(10) rem(24);
    background-color: $white;
    border-radius: rem(100);
    font-weight: bold;
    @include fz(16, 19);
    color: #60355A;

    @include tablets-down() {
      padding: rem(7) rem(20);
      height: rem(72);
      @include fz(14);
    }

    & > img {
      width: rem(78);
      height: rem(78);
      min-width: rem(78);
      margin-right: rem(20);

      @include tablets-down() {
        width: rem(59);
        height: rem(59);
        min-width: rem(59);
        margin-right: rem(12);
      }
    }

    & > span {
      max-width: rem(120);
    }
  }

  &-tooltips {
    position: absolute;
    top: rem(195);
    right: 0;
    width: rem(240);

    @include tablets-down() {
      position: relative;
      top: auto;
      margin: rem(-40) auto 0;
      width: 100%;
      max-width: rem(320);
    }

    @include phones-lg-down() {
      margin: rem(-10) auto 0;
    }

    &__bg {
      width: 160%;
      position: absolute;
      top: 0;
      left: 50%;
      height: 100%;
      transform: translateX(-55%);

      @include tablets-down() {
        width: rem(500);
        transform: translateX(-50%);
      }

      &-inner {
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 0;
        padding-top: 107.3%;
        position: relative;

        @include tablets-down() {
          padding-top: 68.275%;
        }

        @include custom-down(500px) {
          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            background-color: $white;
          }
        }
      }

      &-decor {
        width: rem(85);
        height: rem(89);
        position: absolute;
        top: 100%;
        left: 50%;
        margin-top: rem(-60);
        margin-left: rem(-65);
        display: none;

        @include tablets-down() {
          display: block;
        }
      }

      &-pic {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        &--desk {
          @include tablets-down() {
            display: none;
          }
        }

        &--mob {
          display: none;

          @include tablets-down() {
            display: block;
          }
        }
      }
    }

    &__inner {
      position: relative;

      .swiper-container {
        @include tablets-down() {
          overflow: visible;
        }
      }
    }

    &__controls {
      display: none;
      align-items: center;
      position: absolute;
      top: rem(-45);
      right: rem(-35);

      @include tablets-down() {
        display: flex;
      }

      @include phones-lg-down() {
        right: 0;
      }

      .swiper-button-next,
      .swiper-button-prev {
        position: static;
      }

      .swiper-button-custom {
        outline: none;
        z-index: auto;
        width: rem(49);
        height: rem(49);

        &:not(:last-child) {
          margin-right: rem(14);
        }

        &.swiper-button-disabled {
          opacity: 0.5;
        }
      }
    }
  }

  &-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: 400;
    @include fz(16, 21);
    color: #60355A;

    @include tablets-down() {
      font-size: rem(15);
    }

    strong {
      font-weight: bold;
    }

    & > span {
      color: #E895B5;
      display: block;
      margin-bottom: rem(25);
      font-weight: bold;
    }

    & > p {
      margin: 0 0 rem(40);

      @include tablets-down() {
        margin: 0 0 rem(28);
      }
    }

    & > a {
      display: block;
      font-weight: bold;
      color: #DE0052;
    }
  }

  &-box {
    width: rem(565);
    position: absolute;
    bottom: rem(70);
    left: 50%;
    margin-left: rem(-565 / 2);

    @include desktop-sm-down() {
      width: rem(500);
      margin-left: rem(-500 / 2);
      bottom: rem(90);
    }

    @include tablets-down() {
      width: rem(400);
      position: relative;
      left: auto;
      margin: 0 auto;
      bottom: auto;
    }

    @include phones-lg-down() {
      width: rem(274);
    }

    @include phones-down() {
      width: rem(242);
    }

    &__inner {
      position: relative;
      width: 100%;
      padding-top: 117.2%;
      height: 0;

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .box-elem {
      bottom: rem(-87);

      @include tablets-down() {
        // width: rem(380);
        // margin-left: rem(-380 / 2);
        width: 100%;
        left: 0;
        margin-left: 0;
        bottom: rem(-40);
      }

      @include phones-lg-down() {
        width: rem(260);
      }
    }
  }
}

.home-tooltip-pgn {
  $home-tooltip-pgn: &;
  display: block;
  width: rem(98);
  height: rem(98);
  position: relative;
  border-radius: 50%;
  outline: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;

  @include desktop-sm-down() {
    width: rem(78);
    height: rem(78);
  }

  @include tablets-down() {
    width: rem(60);
    height: rem(60);
  }

  @include phones-down() {
    width: rem(50);
    height: rem(50);
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: rem(8) rem(14) rem(16) transparent;
    transition: all $tr-duration;
  }

  &-active {
    &::before {
      width: 120%;
      height: 120%;
    }
  }

  &-0 {
    top: rem(99);
    left: rem(-11);

    @include tablets-down() {
      left: rem(-30);
    }

    @include phones-lg-down() {
      top: rem(70);
    }

    &::before {
      background-color: #FF8731;
    }
    &.home-tooltip-pgn-active {
      &::before {
        box-shadow: rem(8) rem(14) rem(16) rgba(#FF8731, 0.31);
      }
    }
    .home-tooltip-pgn__small {
      color: #FEBA88;
    }
  }
  &-1 {
    top: rem(-44);
    left: rem(110);

    @include tablets-down() {
      left: rem(40);
      top: 0;
    }

    @include phones-lg-down() {
      top: rem(-5);
      left: rem(10);
    }

    @include phones-down() {
      left: rem(0);
    }

    &::before {
      background-color: #6CB8DB;
    }
    &.home-tooltip-pgn-active {
      &::before {
        box-shadow: rem(8) rem(14) rem(16) rgba(#6CB8DB, 0.31);
      }
    }
    .home-tooltip-pgn__small {
      color: #A0D4EC;
    }
  }
  &-2 {
    top: rem(-35);
    right: rem(146);

    @include tablets-down() {
      right: auto;
      left: 50%;
      margin-left: rem(-78 / 2);
    }

    @include phones-lg-down() {
      margin-left: rem(-60 / 2);
    }

    @include phones-down() {
      margin-left: rem(-50 / 2);
    }

    &::before {
      background-color: #FFBF3F;
    }
    &.home-tooltip-pgn-active {
      &::before {
        box-shadow: rem(8) rem(14) rem(16) rgba(#FFBF3F, 0.31);
      }
    }
    .home-tooltip-pgn__small {
      color: #FBEBCB;
    }
  }
  &-3 {
    top: rem(79);
    right: rem(-7);

    @include tablets-down() {
      top: 0;
      right: rem(40);
    }

    @include phones-lg-down() {
      top: rem(-5);
      right: rem(15);
    }

    @include phones-down() {
      right: 0;
    }

    &::before {
      background-color: #FA5995;
    }
    &.home-tooltip-pgn-active {
      &::before {
        box-shadow: rem(8) rem(14) rem(16) rgba(#FA5995, 0.31);
      }
    }
    .home-tooltip-pgn__small {
      color: #FF9AC0;
    }
  }
  &-4 {
    top: rem(232);
    right: rem(-46);

    @include tablets-down() {
      top: rem(99);
      right: rem(-30);
    }

    @include phones-lg-down() {
      top: rem(70);
    }

    &::before {
      background-color: #93C582;
    }
    &.home-tooltip-pgn-active {
      &::before {
        box-shadow: rem(8) rem(14) rem(16) rgba(#93C582, 0.31);
      }
    }
    .home-tooltip-pgn__small {
      color: #C6E3BD;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__name {
    font-weight: bold;
    @include fz(48, 50);
    color: $white;

    @include tablets-down() {
      @include fz(24);
    }

    sub {
      @include fz(18);
      margin-top: rem(-6);
      display: inline-block;
      bottom: auto;

      @include tablets-down() {
        @include fz(13);
        margin-top: rem(-6);
      }
    }
  }

  &__small {
    font-weight: bold;
    @include fz(12, 14);
    color: $white;

    @include tablets-down() {
      @include fz(8);
    }
  }
}

.box-elem {
  position: absolute;
  left: 50%;
  width: rem(480);
  margin-left: rem(-480 / 2);
  pointer-events: none;

  @include desktop-sm-down() {
    width: rem(450);
    margin-left: rem(-450 / 2);
  }

  &__inner {
    width: 100%;
    position: relative;
    height: 0;
    padding-top: 132.7%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: none;
      transform: translate3d(0px, 0px, 0px);

      @include tablets-down() {
        display: block;
      }
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @include tablets-down() {
        display: none;
      }
    }
  }
}

/*-------------- home-screen --------------*/
.home-screen {
  $home-screen: &;
  overflow: hidden;

  &.home-screen--started {
    #{$home-screen}__content {
      &::before {
        opacity: 0;
      }
    }
  }

  &.home-screen--ended {
    #{$home-screen}__content {
      &::after {
        opacity: 0;
      }
    }
  }

  &__container {
    position: relative;
  }

  &-mob-decor {
    position: absolute;
    left: 50%;
    display: none;

    &-2 {
      top: rem(62);
      width: rem(64);
      height: rem(74);
      margin-left: rem(-64 / 2);
    }

    &-3 {
      top: rem(14);
      width: rem(58);
      height: rem(94);
      margin-left: rem(-58 / 2);
    }

    @include phones-lg-down() {
      display: block;
    }
  }

  &-bg {
    $home-screen-bg: &;
    position: absolute;

    &__inner {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;

      img {
        position: absolute;
        top: rem(250);
        left: 0;
        width: 100%;

        @include tablets-down() {
          top: 0;
        }
      }
    }

    &--1 {
      width: 58%;
      top: 0;
      left: 50%;
      transform: translateX(-25%);

      #{$home-screen-bg}__inner {
        padding-top: 176%;
      }

      @include tablets-down() {
        width: rem(349);
        left: auto;
        right: 0;
        top: 2%;
        transform: translateX(15%);
      }

      @include phones-lg-down() {
        width: rem(249);
      }
    }

    &--2 {
      width: 80%;
      top: -20%;
      left: 0;
      transform: translateX(-30%);

      #{$home-screen-bg}__inner {
        padding-top: 186.8%;
      }

      @include tablets-down() {
        width: rem(349);
        left: auto;
        top: rem(30);
        right: 0;
        transform: translateX(20%);

        #{$home-screen-bg}__inner {
          img {
            transform: scaleX(-1);
          }
        }
      }

      @include phones-lg-down() {
        width: rem(249);
      }
    }

    &--3 {
      width: 65%;
      top: -20%;
      right: 0;

      #{$home-screen-bg}__inner {
        padding-top: 164%;
        overflow: visible;
      }

      @include tablets-down() {
        width: rem(349);
        top: rem(20);
        transform: translateX(20%);
      }

      @include phones-lg-down() {
        width: rem(249);
      }
    }
  }

  &-label {
    position: absolute;
    right: 0;
    width: rem(179);
    height: rem(179);
    z-index: 1;

    @include desktop-down() {
      width: rem(149);
      height: rem(149);
    }

    @include tablets-down() {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    padding: rem(90) 0 rem(42);
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      height: 100%;
      width: 100vw;
      z-index: 1;
      pointer-events: none;
      transition: opacity $tr-duration;
    }

    &::before {
      right: 80%;
      background: linear-gradient(-270deg, #FFFCFD 80.96%, rgba(255, 255, 255, 0) 100%);
    }
    &::after {
      left: 84%;
      background: linear-gradient(270deg, #FFFCFD 85.96%, rgba(255, 255, 255, 0) 100%);
    }

    @include tablets-down() {
      &::before,
      &::after {
        content: none;
      }
    }
  }

  &-content {
    width: 100%;
    max-width: rem(685);

    .tag {
      margin-bottom: rem(64);
    }

    .section-title {
      margin-bottom: rem(32);

      @include phones-lg-down() {
        @include fz(36, 46);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__btn {
      min-width: rem(200);
      margin-right: rem(14);

      &:last-child {
        margin-right: 0;
      }

      @include tablets-down() {
        min-width: rem(154);
        height: rem(55);
      }
    }

    @include tablets-down() {
      .tag {
        margin-bottom: rem(24);
        @include fz(10);
      }
    }
  }

  &__desc {
    font-weight: normal;
    @include fz(18, 29);
    color: #242B45;
    margin-bottom: rem(64);
    max-width: rem(385);

    @include tablets-down() {
      margin-bottom: rem(32);
      @include fz(15);
    }
  }

  &-slider {
    .swiper-container {
      overflow: visible;
      z-index: auto;
    }

    .swiper-wrapper {
      z-index: auto;
    }

    .swiper-slide {
      height: auto;
      width: rem(358);

      .common-card {
        height: 100%;
      }

      @include phones-lg-down() {
        width: rem(258);
      }
    }

    .swiper-button-custom {
      background: #5C75D6;

      &::after {
        background: url("../img/icons/arrow-next-white.svg") no-repeat 50%/100%;
      }

      &:hover {
        background: #DE0052;
      }
    }

    .swiper-button {
      @include tablets-down() {
        display: none;
      }
    }
  }

  &-1 {
    #{$home-screen}__content {
      padding-top: rem(85);

      &::before {
        right: 100%;
        background: linear-gradient(-270deg, #FFFCFD 90.96%, rgba(255, 255, 255, 0) 100%);
      }
    }

    #{$home-screen}-content {
      margin-bottom: rem(120);
    }

    #{$home-screen}-label {
      top: rem(100);
    }

    @include tablets-down() {
      #{$home-screen}__container {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, #FFFCFD 46.96%, rgba(255, 255, 255, 0) 100%);
          z-index: 1;
        }
      }

      #{$home-screen}__content {
        z-index: 1;
      }

      #{$home-screen}-content {
        margin-bottom: rem(40);
      }
    }
  }

  &-2 {
    background: #FFF0F5;

    #{$home-screen}__content {
      padding-top: rem(100);
    }

    #{$home-screen}-content {
      margin-left: 50%;
      margin-bottom: rem(126);
    }

    #{$home-screen}-label {
      top: rem(140);
    }

    @include tablets-down() {
      #{$home-screen}__container {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, #FFFCFD 46.96%, rgba(255, 255, 255, 0) 100%);
          z-index: 1;
        }
      }

      #{$home-screen}__content {
        padding-top: rem(80);
        z-index: 1;
      }

      #{$home-screen}-content {
        margin-left: 0;
        margin-bottom: rem(55);
        z-index: 1;
      }
    }
  }

  &-3 {
    #{$home-screen}__content {
      padding-top: rem(90);

      &::before {
        right: 100%;
        background: linear-gradient(-270deg, #FFFCFD 90.96%, rgba(255, 255, 255, 0) 100%);
      }
    }

    #{$home-screen}-content {
      margin-bottom: rem(106);
    }

    #{$home-screen}-label {
      top: rem(130);
    }

    @include tablets-down() {
      background: #FFF0F5;

      #{$home-screen}__container {
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, #FFFCFD 46.96%, rgba(255, 255, 255, 0) 100%);
          z-index: 1;
        }
      }

      #{$home-screen}__content {
        z-index: 1;
      }

      #{$home-screen}-content {
        margin-bottom: rem(65);

        .tag {
          background: $white;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(32);
  }
}

.common-card {
  $common-card: &;

  &:hover {
    @include tablets-up() {
      // #{$common-card}__inner {
        // box-shadow: 0 rem(4) rem(24) transparent;
        // box-shadow: 0 rem(4) rem(24) transparent;
        // box-shadow: 0px 4px 24px #F9E5E9;
      // }

      #{$common-card}__body {
        transform: translate3d(0, rem(-70), 0);
        // box-shadow: 0 rem(-10) rem(20) rem(-10) #f9e5e9;
        // box-shadow: 0 rem(4) rem(24) transparent;
      }

      #{$common-card}__link {
        color: #DE0052;
      }
    }
  }

  &__inner {
    position: relative;
    background: $white;
    box-shadow: 0 rem(4) rem(24) #F9E5E9;
    border-radius: rem(10);
    transition: box-shadow $tr-hover;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: rem(400);
    padding-bottom: rem(32);
    transform: translate3d(0,0,0);

    @include tablets-down() {
      min-height: rem(315);
    }
  }

  &__logo {
    width: rem(103);
    height: rem(103);
    margin-bottom: rem(24);

    @include tablets-down() {
      width: rem(65);
      height: rem(65);
      margin-bottom: rem(40);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__body {
    flex-grow: 1;
    width: 99.8%;
    padding: rem(36) rem(46) rem(68);
    border-radius: rem(10) rem(10) 0 0;
    transform: translate3d(0,0,0);
    background-color: $white;
    box-shadow: 0 rem(-16) rem(28) rem(-10) #f9e5e9;
    transition: transform $tr-hover;

    @include phones-lg-down() {
      padding: rem(20) rem(20) rem(48);
    }
  }

  &__title {
    font-weight: bold;
    @include fz(18, 22);
    color: #60355A;
    margin: 0 0 rem(32);
  }

  &__desc {
    font-weight: normal;
    @include fz(16, 22);
    color: #60355A;
    max-height: rem(90);

    @include tablets-down() {
      @include fz(14);
    }
  }

  &__footer {
    position: relative;
    padding: 0 rem(46);
  }

  &__link {
    // position: absolute;
    // bottom: rem(59);
    // left: rem(46);
    display: block;
    // transition: opacity $tr-hover, transform $tr-hover;
    transition: color $tr-hover;
    font-weight: bold;
    @include fz(16, 21);
    color: #60355A;
    text-decoration: underline;
    // transform: translate3d(0, rem(20), 0);
    // opacity: 0;

    @include tablets-down() {
      display: none;
    }
  }
}

.home-useful-articles {
  $home-useful-articles: &;
  position: relative;
  background: #FFF2F5;
  padding: 0;

  .section-small-title {
    @include tablets-down() {
      margin-bottom: rem(50);
    }
  }

  &__content {
    padding: rem(90) 0 rem(85);
    position: relative;

    @include tablets-down() {
      padding: rem(60) 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: -10%;
      right: 84%; /* (28% * 3) 3 slides in views 28% */
      pointer-events: none;
      width: 100vw;
      height: 120%;
      background: linear-gradient(-270deg, #FFFBFC 85.96%, rgba(255, 242, 245, 0) 100%);
      z-index: 1;
      transition: opacity 0.1s;
    }

    &::after {
      content: "";
      position: absolute;
      top: -10%;
      left: 84%; /* (28% * 3) 3 slides in views 28% */
      pointer-events: none;
      width: 100vw;
      height: 120%;
      background: linear-gradient(270deg, #FFFBFC 85.96%, rgba(255, 242, 245, 0) 100%);
      transition: opacity 0.1s;
    }

    @include tablets-down() {
      &::before,
      &::after {
        content: none;
      }
    }
  }


  &.useful-articles--started {
    #{$home-useful-articles}__content {
      &::before {
        opacity: 0;
      }
    }
  }

  &.useful-articles--ended {
    #{$home-useful-articles}__content {
      &::after {
        opacity: 0;
      }
    }
  }

  .useful-articles-slider {
    &::before,
    &::after {
      content: none;
    }
  }

  .swiper-button-custom {
    background: #5C75D6;

    &::after {
      background: url("../img/icons/arrow-next-white.svg") no-repeat 50%/100%;
    }

    &:hover {
      background: #DE0052;
    }
  }
}

/*-------------- order-block --------------*/
.order-block {
  padding: rem(90) 0 rem(60);

  @include tablets-down() {
    padding: rem(60) 0 rem(70);
  }

  .section-small-title {
    width: 100%;
    text-align: center;
    margin-bottom: rem(90);

    @include tablets-down() {
      margin: 0 auto rem(45);
      max-width: rem(220);
    }
  }

  .map-block {
    margin-bottom: rem(60);
    height: 530px;
    // position: relative;

    // & > img {
    //   width: 100%;
    //   height: 100%;
    //   @include object-fit(cover);
    // }

    .uteka-widget {
      height: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(50);
    margin-bottom: rem(90);

    .btn {
      width: 100%;
      max-width: rem(274);

      & + .btn {
        margin-left: rem(8);
      }
    }

    @include tablets-down() {
      margin-top: rem(70);
      margin-bottom: rem(70);
      flex-direction: column;

      .btn {

        & + .btn {
          margin-top: rem(24);
        }
      }
    }
  }
}

.pharmacies {
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 rem(-23);

    @include desktop-sm-down() {
      margin: 0 rem(-15);
    }

    @include tablets-down() {
      margin: 0 rem(-10);
    }

    @include phones-lg-down() {
      margin: 0 rem(-5);
    }
  }

  &__col {
    padding: 0 rem(23) rem(46);
    width: 20%;
    display: none;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      display: block;
    }

    &:nth-child(6) {
      @include tablets-down() {
        display: block;
      }
    }

    @include desktop-sm-down() {
      padding: 0 rem(15) rem(30);
    }

    @include tablets-down() {
      padding: 0 rem(10) rem(20);
      width: 33.33333%;
    }

    @include phones-lg-down() {
      padding: 0 rem(5) rem(10);
      width: 50%;
    }
  }

  &.show-all {
    .pharmacies__col {
      display: block;
    }
  }
}

.pharmacies-card {
  display: block;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 rem(4) rem(24) #F9E5E9;
  border-radius: rem(10);
  transition: box-shadow $tr-hover;

  &:hover {
    box-shadow: 0 rem(4) rem(24) #F2C5CF;
  }

  &__inner {
    position: relative;
    width: 100%;
    padding-top: 95.8%;

    img {
      width: 70%;
      height: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @include object-fit(contain);
    }
  }
}
