@import "_media.scss";
/*-------------- header --------------*/
.header {
  $header: &;
  transition: background $tr-duration 0.3s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  padding: 0 rem(110);

  @include custom-down($desktop-lg-down-max) {
    padding: 0 rem(20);
  }

  @include tablets-down() {
    background: #FFFFFF;
    box-shadow: rem(0) rem(17) rem(19) rgba(249, 175, 201, 0.12);
  }

  &.header--scrolled {
    position: fixed;
    background-color: $white;
    box-shadow: 0 rem(17) rem(19) rgba(249, 175, 201, 0.12);
    transition: background $tr-duration;

    #{$header}__content {
      height: rem($header-h-scrolled);
    }

    @include tablets-down() {
      #{$header}__content {
        height: rem($header-h-m);
      }
    }
  }

  &.menu-open {
    background-color: $white;
    box-shadow: 0 rem(17) rem(19) rgba(249, 175, 201, 0.12);
    transition: background $tr-duration;
  }

  &.header--refresh-transition {
    transition: background $tr-duration;
  }

  &-logo {
    width: rem(300);

    @include desktop-lg-down() {
      width: rem(200);
    }

    @include phones-lg-down() {
      width: rem(127);
    }
  }

  &__content {
    height: rem($header-h);
    transition: height $tr-duration;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: rem(1700);
    margin: 0 auto;

    @include tablets-down() {
      height: rem($header-h-m);
    }
  }

  &__actions {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: rem(110);

    @include desktop-lg-down() {
      padding-left: rem(64);
    }

    @include desktop-down() {
      padding-left: rem(40);
    }
  }

  &-pay-btn {
    width: 100%;
    max-width: rem(200);
    // width: rem(200);

    @include desktop-lg-down() {
      max-width: rem(160);
    }

    @include tablets-down() {
      display: none;
    }
  }

  &-dropdown {
    margin-right: auto;

    @include tablets-down() {
      display: none;
    }
  }

  &-nav {
    margin-right: rem(48);

    @include desktop-down() {
      margin-right: rem(28);
    }

    @include tablets-down() {
      display: none;
    }

    & > ul {
      display: flex;
      align-items: center;

      & > li {
        &:not(:last-child) {
          margin-right: rem(35);

          @include desktop-down() {
            margin-right: rem(16);
          }
        }

        a {
          font-weight: 600;
          @include fz(16, 26);
          color: #60355A;
          transition: color $tr-hover;

          &:hover {
            color: #DE0052;
          }

          @include desktop-lg-down() {
            @include fz(14);
          }
        }
      }
    }
  }
}

.nav-dropdown {
  $nav-dropdown: &;
  position: relative;
  display: block;

  &:hover {
    #{$nav-dropdown}__label {
      color: #DE0052;

      &::after {
        transform: rotate(-180deg);
      }
    }

    #{$nav-dropdown}__popup {
      pointer-events: auto;

      & > ul {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__label {
    cursor: pointer;
    font-weight: 600;
    @include fz(16, 26);
    color: #60355A;
    transition: color $tr-hover;
    position: relative;
    padding-right: rem(24);
    display: block;

    &::after {
      content: "";
      width: rem(14);
      height: rem(8);
      background: url("../img/icons/arrow-down-blue.svg") no-repeat 50%/100%;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: rem(-4);
      transition: transform $tr-duration;
    }

    @include desktop-lg-down() {
      @include fz(14);
    }
  }

  &__popup {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    display: block;
    pointer-events: none;

    & > ul {
      padding: rem(16) rem(24);
      border-radius: rem(8);
      background: #FFF9FB;
      margin-top: rem(16);
      box-shadow: 0 rem(5) rem(20) rem(0) #F9E5E9;
      transform: translate3d(0, 8px, 0);
      opacity: 0;
      visibility: hidden;
      transition: transform 0.2s, opacity 0.2s, visibility 0.2s;

      li {
        white-space: nowrap;
        margin-bottom: rem(12);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.logo {
  display: block;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.dropdown {
  $dropdown: &;
  position: relative;

  &.dropdown--open {
    #{$dropdown}__popup {
      opacity: 1;
      visibility: visible;
    }

    #{$dropdown}__text {
      background-color: #FEDAE6;

      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  &__text {
    width: rem(245);
    height: rem(60);
    background: transparent;
    border: rem(2) solid #5C75D6;
    border-radius: rem(100);
    cursor: pointer;
    padding: 0 rem(24);
    transition: background $tr-duration;
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    font-weight: 600;
    @include fz(16, 21);
    color: #27325A;

    &::after {
      content: "";
      width: rem(14);
      height: rem(8);
      background: url("../img/icons/arrow-down-blue.svg") no-repeat 50%/100%;
      position: absolute;
      top: 50%;
      right: rem(24);
      margin-top: rem(-4);
      transition: transform $tr-duration;
    }

    @include desktop-lg-down() {
      width: rem(200);
    }

    @include desktop-sm-down() {
      width: rem(160);
    }
  }

  &__popup {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity $tr-duration, visibility $tr-duration;

    &-inner {
      padding: rem(30) rem(16) rem(18);
      background: #FEDAE6;
      border-radius: rem(5);
      margin-top: rem(14);

      & > ul {
        & > li {
          &:not(:last-child) {
            border-bottom: rem(2) solid #FFF3F7;
          }

          a {
            display: flex;
            align-items: center;
            position: relative;
            font-weight: bold;
            @include fz(16, 21);
            color: #613768;
            padding: rem(24) 0;
            transition: color $tr-hover;

            & > img {
              width: rem(40);
              height: rem(40);
              min-width: rem(40);
              margin-right: rem(20);
            }

            &:hover {
              color: #DE0052;
            }
          }

          &:first-child {
            a {
              padding-top: 0;
            }
          }
          &:last-child {
            a {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
